import { defineMessages } from "react-intl";

export default defineMessages({
    users: { id: "Users", defaultMessage: "Users", description: "Users" },
    buyRequests: {
        id: "Buy requests",
        defaultMessage: "Buy requests",
        description: "Buy requests",
    },
    ideaBox: { id: "Idea box", defaultMessage: "Idea box", description: "Idea box" },
    usersPoints: {
        id: "Users points",
        defaultMessage: "Users points",
        description: "Users points",
    },
    settings: { id: "Settings", defaultMessage: "Settings", description: "Settings" },
    comment: { id: "Comment", defaultMessage: "Comment", description: "Comment" },
    vacations: { id: "Vacations", defaultMessage: "Vacations", description: "Vacations" },
    remotes: { id: "Remotes", defaultMessage: "Remotes", description: "Remotes" },
    paidLeaves: { id: "Paid leaves", defaultMessage: "Paid leaves", description: "Paid leaves" },
    male: { id: "Male", defaultMessage: "Male", description: "Male" },
    female: { id: "Female", defaultMessage: "Female", description: "Female" },
    other: { id: "Other", defaultMessage: "Other", description: "Other" },
    temporary: { id: "Temporary", defaultMessage: "Temporary", description: "Temporary" },
    permanent: { id: "Permanent", defaultMessage: "Permanent", description: "Permanent" },
    parentalLeaves: {
        id: "Parental leaves",
        defaultMessage: "Parental leaves",
        description: "Parental leaves",
    },
    sickLeaves: { id: "Sick leaves", defaultMessage: "Sick leaves", description: "Sick leaves" },
    studentPlans: {
        id: "Student plans",
        defaultMessage: "Student plans",
        description: "Student plans",
    },
    externalPlans: {
        id: "External plans",
        defaultMessage: "External plans",
        description: "External plans",
    },
    Requirements: {
        id: "Requirements",
        defaultMessage: "Requirements",
        description: "Requirements",
    },
    monthlyReport: {
        id: "Monthly report",
        defaultMessage: "Monthly report",
        description: "Monthly report",
    },
    exports: {
        id: "Exports",
        defaultMessage: "Exports",
        description: "Exports",
    },
    exportReports: {
        id: "Export Reports",
        defaultMessage: "Export reports",
        description: "Export reports",
    },
    monthlyReports: {
        id: "Monthly reports",
        defaultMessage: "Monthly reports",
        description: "Monthly reports",
    },
    outgoingInvoice: {
        id: "Outgoing invoice",
        defaultMessage: "Outgoing invoice",
        description: "Outgoing invoice",
    },
    outgoingInvoices: {
        id: "Outgoing invoices",
        defaultMessage: "Outgoing invoices",
        description: "Outgoing invoices",
    },
    outgoingInvoiceDetails: {
        id: "outgoingInvoiceDetails",
        defaultMessage: "Outgoing invoice details",
        description: "Outgoing invoice details",
    },
    outgoingInvoiceDownload: {
        id: "outgoingInvoiceDownload",
        defaultMessage: "Download as PDF",
        description: "Download as PDF",
    },
    outgoingInvoicePrint: {
        id: "outgoingInvoicePrint",
        defaultMessage: "Print invoice",
        description: "Print invoice",
    },
    outgoingInvoicePdfGenerated: {
        id: "outgoingInvoicePdfGenerated",
        defaultMessage: "PDF generated!",
        description: "PDF generated!",
    },
    outgoingInvoiceHtmlGenerated: {
        id: "outgoingInvoiceHtmlGenerated",
        defaultMessage: "Receipt generated!",
        description: "Receipt generated!",
    },
    invoiceFooter: {
        id: "invoiceFooter",
        defaultMessage: "Invoice footer",
        description: "Invoice footer",
    },
    poNumber: {
        id: "poNumber",
        defaultMessage: "PO number",
    },
    Partners: {
        id: "Partners",
        defaultMessage: "Partners",
        description: "Partners",
    },
    employee: {
        id: "employee",
        defaultMessage: "Employee",
        description: "Employee",
    },
    Employees: {
        id: "Employees",
        defaultMessage: "Employees",
        description: "Employees",
    },
    Students: {
        id: "Students",
        defaultMessage: "Students",
        description: "Students",
    },
    Externals: {
        id: "Externals",
        defaultMessage: "Externals",
        description: "Externals",
    },
    otherProjects: {
        id: "Other projects",
        defaultMessage: "Other projects",
        description: "Other projects",
    },
    upcomingBirthdays: {
        id: "upcomingBirthdays",
        defaultMessage: "Upcoming Birthdays",
        description: "Upcoming Birthdays",
    },
    monthlyReportLoadingfailed: {
        id: "montlyReport.error.attendanceLoading",
        defaultMessage: "Error while loading monthly work attendances",
        description: "Error while loading monthly work attendances",
    },
    educations: { id: "Educations", defaultMessage: "Educations", description: "Educations" },
    newsFeed: { id: "News feed", defaultMessage: "News feed", description: "News feed" },
    assignments: { id: "Assignments", defaultMessage: "Assignments", description: "Assignments" },
    departments: { id: "Departments", defaultMessage: "Departments", description: "Departments" },
    inventories: { id: "Inventories", defaultMessage: "Inventories", description: "Inventories" },
    services: { id: "services", defaultMessage: "Services", description: "Services" },
    banks: { id: "banks", defaultMessage: "Banks", description: "Banks" },
    ibans: { id: "ibans", defaultMessage: "Ibans", description: "Ibans" },
    accounts: { id: "accounts", defaultMessage: "Accounts", description: "Accounts" },
    travelWarrants: {
        id: "Travel warrants",
        defaultMessage: "Travel warrants",
        description: "Travel warrants",
    },
    paychecks: { id: "Paychecks", defaultMessage: "UsPaychecksers", description: "Paychecks" },
    accessRights: {
        id: "Access rights",
        defaultMessage: "Access rights",
        description: "Access rights",
    },
    addAccessRights: {
        id: "accessRights.add",
        defaultMessage: "Add access right",
        description: "Add access right",
    },
    newAccessRightSubmitValidation: {
        id: "accessRight.validation.success",
        defaultMessage: "Access right successfully created.",
        description: "Access right successfully created",
    },
    homePageStudentPlanRequests: {
        id: "homePageStudentPlanRequests",
        defaultMessage: "#Student #plan requests pending",
        description: "#Student #plan requests pending",
    },
    homePageExternalPlanRequests: {
        id: "homePageExternalPlanRequests",
        defaultMessage: "#External #plan requests pending",
        description: "#External #plan requests pending",
    },
    homePageUnprocessedInvoiceRequests: {
        id: "homePageUnprocessedInvoiceRequests",
        defaultMessage: "#Unprocessed #invoice requests pending",
        description: "#Unprocessed #invoices requests pending",
    },
    homePageUnprocessedBankStatementRequests: {
        id: "homePageUnprocessedBankStatementRequests",
        defaultMessage: "#Unprocessed #bank #statement requests pending",
        description: "Unprocessed #bank #statement requests pending",
    },
    homePageRemoteWorkRequests: {
        id: "homePageRemoteWorkRequests",
        defaultMessage: "#Remote #work requests pending",
        description: "#Remote #work requests pending",
    },
    homePageVacationRequests: {
        id: "homePageVacationRequests",
        defaultMessage: "#Vacation requests pending",
        description: "#Vacation requests pending",
    },
    homePageYearlyVacationplanRequests: {
        id: "homePageYearlyVacationplanRequests",
        defaultMessage: "#Yearly #vacation #plan requests pending",
        description: "#Yearly #vacation #plan requests pending",
    },
    homePageSickLeaveRequests: {
        id: "homePageSickLeaveRequests",
        defaultMessage: "#Sick #leave requests pending",
        description: "#Sick #leave requests pending",
    },
    homePagePaidLeaveRequests: {
        id: "homePagePaidLeaveRequests",
        defaultMessage: "#Paid #leave requests pending",
        description: "#Paid #leave requests pending",
    },
    homePageUnpaidLeaveRequests: {
        id: "homePageUnpaidLeaveRequests",
        defaultMessage: "#Unpaid #leave requests pending",
        description: "#Unpaid #leave requests pending",
    },
    homePageParentalLeaveRequests: {
        id: "homePageParentalLeaveRequests",
        defaultMessage: "#Parental #leave requests pending",
        description: "#Parental #leave requests pending",
    },
    homePageEmployeeContractEnding: {
        id: "homePageEmployeeContractEnding",
        defaultMessage: "#Employee #contract ending requests pending",
        description: "#Employee #contracts ending requests pending",
    },
    homePageIdeaRequests: {
        id: "homePageIdeaRequests",
        defaultMessage: "#Idea requests pending",
        description: "#Idea requests pending",
    },
    homePageBuyRequests: {
        id: "homePageBuyRequests",
        defaultMessage: "#Buy #requests pending",
        description: "#Buy #requests pending",
    },
    editAccessRight: {
        id: "accessRight.edit",
        defaultMessage: "Edit access right",
        description: "Edit access right",
    },
    editAccessRightSubmit: {
        id: "accessRight.edit.success",
        defaultMessage: "Access right successfully edited.",
        description: "Access right successfully edited",
    },
    accessRightValidateAdd: {
        id: "accessRights.validation.unsuccessfulAdd",
        defaultMessage: "Unable to add new Access right",
        description: "Unable to add new Access right",
    },
    accessRightValidateFetch: {
        id: "accessRights.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch access right details",
        description: "Unable to fetch access right details",
    },
    accessRight: {
        id: "accessRight",
        defaultMessage: "Access right",
        description: "Access right",
    },
    accessRightDetails: {
        id: "accessRights.table.projectDetails",
        defaultMessage: "Access right details",
        description: "Access right details",
    },
    accessRightResource: {
        id: "accessRights.resource",
        defaultMessage: "Resource",
        description: "Resource",
    },
    accessRightIsInternal: {
        id: "accessRights.isInternal",
        defaultMessage: "Is Internal",
        description: "Is Internal",
    },
    accessRightIsArchived: {
        id: "accessRights.isArchived",
        defaultMessage: "Is Archived",
        description: "Is Archived",
    },
    accessRightClaimRequirement: {
        id: "accessRights.requiredClaim",
        defaultMessage: "Resource is required",
        description: "Resource is required",
    },
    accessRightResposiblePartyRequirement: {
        id: "accessRights.requiredResposibleParty",
        defaultMessage: "Resposible party is required",
        description: "Resposible party is required",
    },
    accessRightResposiblePersonRequirement: {
        id: "accessRights.requiredResposiblePerson",
        defaultMessage: "Resposible person is required",
        description: "Resposible person is required",
    },
    accessRightSuccessfullyDeleted: {
        id: "accessRights.validation.successfullyDeleted",
        defaultMessage: "Access right successfully deleted",
        description: "Access right successfully deleted",
    },
    accessRightSuccessfullyArchived: {
        id: "accessRights.validation.successfullyArchived",
        defaultMessage: "Access right successfully archived",
        description: "Access right successfully archived",
    },
    accessRightValidateDelete: {
        id: "accessRights.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete access right",
        description: "Unable to delete access right",
    },
    accessRightValidateArchive: {
        id: "accessRights.validation.unsuccessfulDelete",
        defaultMessage: "Unable to archive access right",
        description: "Unable to archive access right",
    },
    accessRightValidateEdit: {
        id: "accessRights.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit access right details",
        description: "Unable to edit access right details",
    },
    accessRightNotFound: {
        id: "accessRights.validation.notFound",
        defaultMessage: "Access right not found",
        description: "Access right not found",
    },
    accessRightRecipient: {
        id: "accessRights.table.recipient",
        defaultMessage: "Access right recipient",
        description: "Access right recipient",
    },
    accessRightRecipients: {
        id: "accessRights.table.recipients",
        defaultMessage: "Access right recipients",
        description: "Access right recipients",
    },
    accessRightEmptyRecipients: {
        id: "projects.table.accessRightEmptyRecipients",
        defaultMessage: "No recipients",
        description: "No recipients",
    },
    editAccessRightRecipients: {
        id: "accessRights.table.editRecipients",
        defaultMessage: "Edit access right recipients",
        description: "Edit access right recipients",
    },
    editAccessRightRecipient: {
        id: "accessRights.table.editRecipient",
        defaultMessage: "Edit access right recipient",
        description: "Edit access right recipient",
    },
    addNewAccessRightRecipient: {
        id: "accessRights.actions.addNewAccessRightRecipient",
        defaultMessage: "Add new access right recipient",
        description: "Add new access right recipient",
    },
    deletedAccessRightRecipient: {
        id: "accessRights.actions.deletedAccessRightRecipient",
        defaultMessage: "Access right recipient successfully deleted",
        description: "Access right recipient successfully deleted",
    },
    accessRightRecipientValidateDelete: {
        id: "accessRights.validation.unsuccessfulRecipientDelete",
        defaultMessage: "Unable to delete access right recipient",
        description: "Unable to delete access right recipient",
    },
    accessRightUnableToGetPartners: {
        id: "accessRights.unableToGetPartners",
        defaultMessage: "Unable to get partners",
        description: "Unable to get partners",
    },
    accessRightUnableToGetContacts: {
        id: "accessRights.unableToGetContacts",
        defaultMessage: "Unable to get partner contacts / employees",
        description: "Unable to get partner contacts / employees",
    },
    accessRightArchiveConfirmation: {
        id: "accessRights.archiveConfirmation",
        defaultMessage: "Are you sure you want to archive this access right?",
        description: "Are you sure you want to archive this access right?",
    },
    confirmDeleteAccessRight: {
        id: "accessRights.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this resource?",
        description: "Are you sure you want to delete this resource?",
    },
    confirmDeleteAccessRightRecipient: {
        id: "accessRights.recipients.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this recipient?",
        description: "Are you sure you want to delete this recipient?",
    },
    responsibleParty: {
        id: "responsibleParty",
        defaultMessage: "Responsible party",
        description: "Responsible party",
    },
    responsiblePerson: {
        id: "responsiblePerson",
        defaultMessage: "Responsible person",
        description: "Responsible person",
    },
    PersonalDetails: {
        id: "Personal details",
        defaultMessage: "Personal details",
        description: "Personal details",
    },
    Access: {
        id: "Access",
        defaultMessage: "Access",
        description: "Access",
    },
    Education: {
        id: "Education",
        defaultMessage: "Education",
        description: "Education",
    },
    attendees: {
        id: "attendees",
        defaultMessage: "Attendees",
        description: "Attendees",
    },
    partnerAttendees: {
        id: "partnerAttendees",
        defaultMessage: "Partner Attendees",
        description: "Partner Attendees",
    },
    claim: { id: "Claim", defaultMessage: "Claim", description: "Claim" },
    vehicle: { id: "Vehicle", defaultMessage: "Vehicle", description: "Vehicle" },
    vehicles: { id: "Vehicles", defaultMessage: "Vehicles", description: "Vehicles" },
    location: { id: "Location", defaultMessage: "Location", description: "Location" },
    locations: { id: "Locations", defaultMessage: "Locations", description: "Locations" },
    locationSuccessfullyDeleted: {
        id: "location.successfully.deleted",
        defaultMessage: "Location successfully deleted",
        description: "Location successfully deleted",
    },
    locationValidateDelete: {
        id: "location.unsuccessful.delete",
        defaultMessage: "Unable to delete location",
        description: "Unable to delete location",
    },
    locationsValidateFetch: {
        id: "locations.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching locations",
        description: "Error while fetching locations",
    },
    projects: { id: "Projects", defaultMessage: "Projects", description: "Projects" },
    projectNote: {
        id: "projectNote",
        defaultMessage: "Project note",
        description: "Project note",
    },
    projectNotes: {
        id: "Project notes",
        defaultMessage: "Project notes",
        description: "Project notes",
    },
    addDocument: {
        id: "document.add",
        defaultMessage: "Add document",
        description: "Add document",
    },
    addDocumentClassification: {
        id: "documentClassification.add",
        defaultMessage: "Add document classification",
        description: "Add document classification",
    },
    addDocumentCategory: {
        id: "documentCategory.add",
        defaultMessage: "Add document category",
        description: "Add document category",
    },
    addServiceCategory: {
        id: "serviceCategory.add",
        defaultMessage: "Add service category",
        description: "Add service category",
    },
    addIban: {
        id: "iban.add",
        defaultMessage: "Add IBAN",
        description: "Add IBAN",
    },
    addInventoryItem: {
        id: "inventoryItem.add",
        defaultMessage: "Add inventory item",
        description: "Add inventory item",
    },
    inventoryItemHistory: {
        id: "inventoryItem.history",
        defaultMessage: "Inventory item history",
        description: "Inventory item history",
    },
    inventoryItemHistoryMessageFetchingError: {
        id: "inventoryItemHistoryMessageFetchingError.history",
        defaultMessage: "Erroy while fetching inventory item history",
        description: "Erroy while fetching inventory item history",
    },
    importInventoryItems: {
        id: "inventoryItem.import",
        defaultMessage: "Import inventory items",
        description: "Import inventory items",
    },
    exportInventory: {
        id: "inventoryItem.export",
        defaultMessage: "Export inventory",
        description: "Export inventory",
    },
    documentName: {
        id: "document.name",
        defaultMessage: "Document name",
        description: "Document name",
    },
    documentCategory: {
        id: "document.category",
        defaultMessage: "Document category",
        description: "Document category",
    },
    documentUnableToGetDocumentCategories: {
        id: "document.unableToGetCategories",
        defaultMessage: "Unable to get document categories",
        description: "Unable to get document categories",
    },
    documentAction: {
        id: "document.action",
        defaultMessage: "Action",
        description: "Document action",
    },
    createNewDocument: {
        id: "document.createNew",
        defaultMessage: "Create new document",
        description: "Create new document",
    },
    validUntil: {
        id: "document.validUntil",
        defaultMessage: "Valid until",
        description: "Valid until",
    },
    documentDetails: {
        id: "document.details",
        defaultMessage: "Document details",
        description: "Document details",
    },
    documentVersion: {
        id: "document.documentVersion",
        defaultMessage: "Document version",
        description: "Document version",
    },
    documentIsActive: {
        id: "document.isActive",
        defaultMessage: "Is active",
        description: "Document is active",
    },
    documentClassification: {
        id: "document.classification",
        defaultMessage: "Document classification",
        description: "Document classification",
    },
    documentUnableToGetDocumentClassifications: {
        id: "document.unableToGetClassifications",
        defaultMessage: "Unable to get document classification",
        description: "Unable to get document classification",
    },
    documentComment: {
        id: "document.comment",
        defaultMessage: "Comment",
        description: "Document comment",
    },
    documentIsRevoked: {
        id: "document.isRevoked",
        defaultMessage: "Is revoked",
        description: "Document is revoked",
    },
    documentNotFound: {
        id: "document.validation.notFound",
        defaultMessage: "Document not found",
        description: "Document not found",
    },
    documentNotRevoked: {
        id: "document.notRevoked",
        defaultMessage: "Not revoked",
        description: "Not revoked",
    },
    documentRevoked: {
        id: "document.revoked",
        defaultMessage: "Revoked",
        description: "Revoked",
    },
    documentVersionNotFound: {
        id: "documentVersion.validation.notFound",
        defaultMessage: "Document version not found",
        description: "Document version not found",
    },
    addProject: { id: "projects.add", defaultMessage: "Add project", description: "Add project" },
    addProjectRole: {
        id: "projectRole.add",
        defaultMessage: "Add project role",
        description: "Add project role",
    },
    confirmDeleteProjectRole: {
        id: "projectRole.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this project role?",
        description: "Are you sure you want to delete this project role?",
    },
    editProject: {
        id: "projects.edit",
        defaultMessage: "Edit project",
        description: "Edit project",
    },
    addProjectNote: {
        id: "projectNotes.add",
        defaultMessage: "Add project note",
        description: "Add project note",
    },
    editProjectNote: {
        id: "projectNotes.edit",
        defaultMessage: "Edit project note",
        description: "Edit project note",
    },
    projectCode: {
        id: "projects.table.projectCode",
        defaultMessage: "Project code",
        description: "Project code",
    },
    projectRole: {
        id: "projectRole",
        defaultMessage: "Project role",
        description: "Project role",
    },
    projectRoleNew: {
        id: "projectRoleNew",
        defaultMessage: "Project role",
        description: "Project role",
    },
    noGrouping: {
        id: "reports.noGrouping",
        defaultMessage: "No grouping",
        description: "No grouping",
    },
    byProject: { id: "reports.byProject", defaultMessage: "By project", description: "By project" },
    byPerson: { id: "reports.byPerson", defaultMessage: "By person", description: "By person" },
    name: { id: "general.name", defaultMessage: "Name", description: "Name" },
    text: { id: "general.text", defaultMessage: "Text", description: "Text" },
    content: { id: "general.content", defaultMessage: "Content", description: "Content" },
    hours: { id: "general.hours", defaultMessage: "Hours", description: "Hours" },
    category: { id: "general.category", defaultMessage: "Category", description: "Category" },
    description: {
        id: "general.description",
        defaultMessage: "Description",
        description: "Description",
    },
    status: { id: "general.status", defaultMessage: "Status", description: "Status" },
    partner: { id: "general.partner", defaultMessage: "Partner", description: "Partner" },
    partnerContact: {
        id: "general.partnerContact",
        defaultMessage: "Partner Contact",
        description: "Partner Contact",
    },
    projectUrl: {
        id: "projects.table.projectUrl",
        defaultMessage: "Project URL",
        description: "Project URL",
    },
    actions: { id: "general.actions", defaultMessage: "Actions", description: "Actions" },
    action: { id: "general.action", defaultMessage: "Action", description: "Action" },
    timestamp: { id: "general.timestamp", defaultMessage: "Timestamp", description: "Timestamp" },
    details: { id: "general.details", defaultMessage: "Details", description: "Details" },
    remarks: { id: "general.remarks", defaultMessage: "Remarks", description: "Remarks" },
    internalRemarks: {
        id: "general.internalRemarks",
        defaultMessage: "Internal remarks",
        description: "Internal Remarks",
    },
    projectDetails: {
        id: "projects.table.projectDetails",
        defaultMessage: "Project details",
        description: "Project details",
    },
    editProjectMembers: {
        id: "projects.assignment.table.editProjectMembers",
        defaultMessage: "Edit project members",
        description: "Edit project members",
    },
    projectMember: {
        id: "projects.assignment.table.projectMember",
        defaultMessage: "Project member",
        description: "Project member",
    },
    projectMembers: {
        id: "projects.assignment.table.projectMembers",
        defaultMessage: "Project members",
        description: "Project members",
    },
    projectEmptyMembers: {
        id: "projects.assignment.table.projectEmptyMembers",
        defaultMessage: "No members",
        description: "No members",
    },
    projectNotesDetails: {
        id: "projectsNotes.table.projectDetails",
        defaultMessage: "Project notes details",
        description: "Project notes details",
    },
    minutesOfTheMeeting: {
        id: "projectsNotes.minutesOfTheMeeting",
        defaultMessage: "Minutes Of The Meeting",
        description: "Minutes Of The Meeting",
    },
    user: { id: "general.user", defaultMessage: "User", description: "User" },
    roles: { id: "general.roles", defaultMessage: "Roles", description: "Roles" },
    gitLabWebhookToken: {
        id: "projects.table.gitLabWebHookToken",
        defaultMessage: "GitLab WebHook Token",
        description: "GitLab WebHook Token",
    },
    onlineRepository: {
        id: "projects.table.onlineRepository",
        defaultMessage: "Online Repository",
        description: "Online Repository",
    },
    gitlabProjectId: {
        id: "projects.table.gitlabProjectId",
        defaultMessage: "Gitlab project ID",
        description: "Gitlab project ID",
    },
    nameRequirement: {
        id: "general.validation.requiredName",
        defaultMessage: "Name is required",
        description: "Name is required",
    },
    titleRequirement: {
        id: "general.validation.requiredTitle",
        defaultMessage: "Title is required",
        description: "Title is required",
    },
    projectRequirement: {
        id: "projects.validation.requiredProject",
        defaultMessage: "Project is required",
        description: "Project is required",
    },
    priceRequirement: {
        id: "general.validation.requiredprice",
        defaultMessage: "Price is required",
        description: "Price is required",
    },
    latestBankStatementDate: {
        id: "general.home.latestBankStatementDate",
        defaultMessage: "Bank statement was last uploaded",
        description: "Bank statement was last uploaded",
    },
    projectCodeRequirement: {
        id: "projects.validation.requiredCode",
        defaultMessage: "Project code is required.",
        description: "Project code is required.",
    },
    locationRequirement: {
        id: "general.validation.requiredLocation",
        defaultMessage: "Location is required",
        description: "Location is required",
    },
    dateRequirement: {
        id: "general.validation.requiredDate",
        defaultMessage: "Date is required",
        description: "Date is required",
    },
    valueRequirement: {
        id: "general.validation.requiredValue",
        defaultMessage: "Value is required",
        description: "Value is required",
    },
    employeeRequirement: {
        id: "general.validation.requiredEmployee",
        defaultMessage: "Employee is required",
        description: "Employee is required",
    },
    partnerRequirement: {
        id: "general.validation.requiredPartner",
        defaultMessage: "Partner is required",
        description: "Partner is required",
    },
    typeRequirement: {
        id: "general.validation.requiredType",
        defaultMessage: "Type is required",
        description: "Type is required",
    },
    kindRequirement: {
        id: "general.validation.requiredKind",
        defaultMessage: "Kind is required",
        description: "Kind is required",
    },
    statusRequirement: {
        id: "general.validation.requiredStatus",
        defaultMessage: "Status is required",
        description: "Status is required",
    },
    newProjectSubmitValidation: {
        id: "projects.validation.success",
        defaultMessage: "Project successfully created.",
        description: "Project successfully created",
    },
    projectSuccessfullyDeleted: {
        id: "projects.validation.successfullyDeleted",
        defaultMessage: "Project successfully deleted",
        description: "Project successfully deleted",
    },
    projectValidateAdd: {
        id: "projects.validation.unsuccessfulAdd",
        defaultMessage: "Unable to add new project",
        description: "Unable to add new project",
    },
    projectValidateEdit: {
        id: "projects.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit project details",
        description: "Unable to edit project details",
    },
    projectValidateFetch: {
        id: "projects.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch project details",
        description: "Unable to fetch project details",
    },
    projectValidateDelete: {
        id: "projects.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete project",
        description: "Unable to delete project",
    },
    projectNotFound: {
        id: "projects.validation.notFound",
        defaultMessage: "Project not found",
        description: "Project not found",
    },
    confirmDelete: {
        id: "projects.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this project?",
        description: "Are you sure you want to delete this project?",
    },
    confirmDeleteProjectNotes: {
        id: "projectNotes.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this project notes?",
        description: "Are you sure you want to delete this project notes?",
    },
    newProjectNotesSubmitValidation: {
        id: "projectNotes.validation.success",
        defaultMessage: "Project notes successfully created.",
        description: "Project notes successfully created",
    },
    projectNotesValidateAdd: {
        id: "projectNotes.validation.unsuccessfulAdd",
        defaultMessage: "Unable to add new project notes",
        description: "Unable to add new project notes",
    },
    projectNotesSuccessfullyDeleted: {
        id: "projectNotes.validation.successfullyDeleted",
        defaultMessage: "Project notes successfully deleted",
        description: "Project notes successfully deleted",
    },
    projectNotesValidateDelete: {
        id: "projectNotes.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete project notes",
        description: "Unable to delete project notes",
    },
    projectNotesValidateFetch: {
        id: "projectNotes.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching project notes",
        description: "Error while fetching project notes",
    },
    continueEditingProjectNotesDetails: {
        id: "general.actions.continueEditingProjectNotesDetails",
        defaultMessage:
            "Project notes successfully created. Do you want to continue editing project notes details?",
        description:
            "Project notes successfully created. Do you want to continue editing project notes details?",
    },
    confirmDeleteBankStatement: {
        id: "bankStatements.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this bank statement?",
        description: "Are you sure you want to delete this bank statement?",
    },

    confirmDeleteIncomingInvoice: {
        id: "incomingInvoices.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this incoming invoice?",
        description: "Are you sure you want to delete this incoming invoice",
    },


    confirmDeleteIban: {
        id: "ibans.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this iban?",
        description: "Are you sure you want to delete this iban?",
    },

    confirmDeleteBankStatements: {
        id: "bankStatements.validation.confirmDeletes",
        defaultMessage: "Are you sure you want to delete these bank statements?",
        description: "Are you sure you want to delete this bank statements?",
    },

    confirmDeleteInvoice: {
        id: "invoice.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this invoice?",
        description: "Are you sure you want to delete this invoice?",
    },
    confirmDeleteInvoices: {
        id: "invoice.validation.confirmDeletes",
        defaultMessage: "Are you sure you want to delete these invoices?",
        description: "Are you sure you want to delete these invoices?",
    },

    projectMemberSuccessfullyDeleted: {
        id: "projectMembers.validation.successfullyDeleted",
        defaultMessage: "Project member successfully deleted",
        description: "Project member successfully deleted",
    },
    projectMemberValidateDelete: {
        id: "projectMembers.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete project member",
        description: "Unable to delete project member",
    },
    confirmDeleteProjectMember: {
        id: "projectMembers.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this project member?",
        description: "Are you sure you want to delete this project member?",
    },
    confirmation: {
        id: "general.confirmation",
        defaultMessage: "Confirmation",
        description: "Confirmation",
    },
    addNewProjectMember: {
        id: "projects.actions.addNewProjectMember",
        defaultMessage: "Add new project member",
        description: "Add new project member",
    },
    editProjectMember: {
        id: "projects.actions.editProjectMember",
        defaultMessage: "Edit project member",
        description: "Edit project member",
    },
    upload: {
        id: "general.actions.upload",
        defaultMessage: "Upload",
        description: "Upload",
    },
    viewProfilePicture: {
        id: "general.actions.view.profile.image",
        defaultMessage: "View profile image",
        description: "View profile image",
    },
    uploaded: {
        id: "general.uploaded",
        defaultMessage: "Uploaded",
        description: "Uploaded",
    },
    uploadedBy: {
        id: "general.uploadedBy",
        defaultMessage: "Uploaded by",
        description: "Uploaded by",
    },
    fileName: {
        id: "general.fileName",
        defaultMessage: "File name",
        description: "File name",
    },
    auditable: {
        id: "general.auditable",
        defaultMessage: "Auditable",
        description: "Auditable",
    },
    uploadBankStatement: {
        id: "bankStatement.upload",
        defaultMessage: "Upload bank statements",
        description: "Upload bank statements",
    },
    uploadInvoice: {
        id: "invoice.upload",
        defaultMessage: "Upload invoice",
        description: "Upload invoice",
    },
    uploadDocument: {
        id: "document.upload",
        defaultMessage: "Upload document",
        description: "Upload document",
    },
    documentTooltipDownload: {
        id: "document.tooltipDownload",
        defaultMessage: "Download active version",
        description: "Download document",
    },
    choose: {
        id: "general.actions.choose",
        defaultMessage: "Choose",
        description: "Choose",
    },
    selectUser: {
        id: "general.actions.selectUser",
        defaultMessage: "Select a user",
        description: "Select a user",
    },
    selectRoles: {
        id: "general.actions.selectRoles",
        defaultMessage: "Select roles",
        description: "Select roles",
    },
    setEndDate: {
        id: "projects.actions.setEndDate",
        defaultMessage: "Set end date",
        description: "Set end date",
    },
    submit: {
        id: "general.actions.submit",
        defaultMessage: "Submit",
        description: "Submit",
    },
    unableToFetchInvoiceDetails: {
        id: "unableToFetchInvoiceDetails",
        defaultMessage: "Unable to fetch invoice details",
        description: "Unable to fetch invoice details",
    },
    unableToDeleteInvoice: {
        id: "unableToDeleteInvoice",
        defaultMessage: "Unable to delete invoice",
        description: "Unable to delete invoice",
    },
    unableToEditInvoice: {
        id: "unableToEditInvoice",
        defaultMessage: "Unable to edit invoice",
        description: "Unable to edit invoice",
    },
    unableToCreateInvoice: {
        id: "unableToCreateInvoice",
        defaultMessage: "Unable to create invoice",
        description: "Unable to create invoice",
    },
    unableToEditInvoiceDetails: {
        id: "unableToEditInvoiceDetails",
        defaultMessage: "Unable to edit invoice details",
        description: "Unable to edit invoice details",
    },
    unableToGeneratePdfReceipt: {
        id: "unableToGeneratePdfReceipt",
        defaultMessage: "Unable to generate PDF receipt",
        description: "Unable to generate PDF receipt",
    },
    unableToGenerateHtmlReceipt: {
        id: "unableToGeneratePdfReceipt",
        defaultMessage: "Unable to generate PDF receipt",
        description: "Unable to generate PDF receipt",
    },
    documentSuccessfullyUploaded: {
        id: "documentSuccessfullyUploaded",
        defaultMessage: "Document successfully uploaded",
        description: "Document successfully uploaded",
    },
    documentReady: {
        id: "documentReady",
        defaultMessage: "Document ready. Press submit to create new document(s).",
        description: "Document ready. Press submit to create new document(s).",
    },
    documentReadySickLeave: {
        id: "documentReadySickLeave",
        defaultMessage:
            "Document ready. Press submit or start sick leave to create new document(s).",
        description: "Document ready. Press submit or start sick leave to create new document(s).",
    },
    unableToAddDocument: {
        id: "unableToAddDocument",
        defaultMessage: "Unable to add new document",
        description: "Unable to add new document",
    },
    editInvoice: {
        id: "editInvoice",
        defaultMessage: "Edit invoice",
        description: "Edit invoice",
    },
    invoiceDetails: {
        id: "invoiceDetails",
        defaultMessage: "Invoice details",
        description: "Invoice details",
    },
    isInVATSystem: {
        id: "isInVATSystem",
        defaultMessage: "Is in VAT system",
        description: "Is in VAT system",
    },
    isTravelExpense: {
        id: "isTravelExpense",
        defaultMessage: "Is travel expense",
        description: "Is travel expense",
    },
    categoryExpense: {
        id: "categoryExpense",
        defaultMessage: "Category expense",
        description: "Category expense",
    },
    addService: {
        id: "addService",
        defaultMessage: "Add new service",
        description: "Add new service",
    },
    deleteService: {
        id: "deleteService",
        defaultMessage: "Delete service",
        description: "Delete service",
    },
    editService: {
        id: "editService",
        defaultMessage: "Edit service",
        description: "Edit service",
    },
    serviceType: {
        id: "serviceType",
        defaultMessage: "Service type",
        description: "Service type",
    },
    serviceCategory: {
        id: "serviceCategory",
        defaultMessage: "Service category",
        description: "Service category",
    },
    serviceCode: {
        id: "serviceCode",
        defaultMessage: "Service code",
        description: "Service code",
    },
    serviceValidateFetch: {
        id: "service.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching service",
        description: "Error while fetching service",
    },
    servicesValidateFetch: {
        id: "services.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching services",
        description: "Error while fetching services",
    },
    noServicesToDisplay: {
        id: "services.validation.noDisplay",
        defaultMessage: "No services to display",
        description: "No services to display",
    },
    serviceSuccessfullyDeleted: {
        id: "services.validation.serviceSuccessfullyDeleted",
        defaultMessage: "Service successfully deleted",
        description: "Service successfully deleted",
    },
    servicesValidateDelete: {
        id: "services.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete services",
        description: "Unable to delete services",
    },
    serviceDetailsValidateFetch: {
        id: "serviceDetails.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch requested service",
        description: "Unable to fetch requested service",
    },
    serviceDetailsValidateDelete: {
        id: "serviceDetails.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete selected service",
        description: "Unable to delete selected service",
    },
    serviceDetailsValidateEdit: {
        id: "serviceDetails.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit selected service",
        description: "Unable to edit selected service",
    },
    servicesFormCreateError: {
        id: "servicesForm.validation.createError",
        defaultMessage: "Unable to create new service",
        description: "Unable to create new service",
    },
    unitMeasure: {
        id: "unitMeasure",
        defaultMessage: "Unit measure",
        description: "Unit measure",
    },
    serviceDetails: {
        id: "serviceDetails",
        defaultMessage: "Service details",
        description: "Service details",
    },
    contract: { id: "contract", defaultMessage: "Contract", description: "Contract" },
    contracts: { id: "contracts", defaultMessage: "Contracts", description: "Contracts" },
    contractCode: {
        id: "contractCode",
        defaultMessage: "Contract code",
        description: "Contract code",
    },
    contractType: {
        id: "contractType",
        defaultMessage: "Contract type",
        description: "Contract type",
    },
    contractKind: {
        id: "contractKind",
        defaultMessage: "Contract kind",
        description: "Contract kind",
    },
    contractStatus: {
        id: "contractStatus",
        defaultMessage: "Contract status",
        description: "Contract status",
    },
    contractUrl: {
        id: "contractUrl",
        defaultMessage: "URL",
        description: "URL",
    },
    contractSummaryAndRisks: {
        id: "contractSummaryAndRisks",
        defaultMessage: "Summary and risks",
        description: "Summary and risks",
    },
    contractUserType: {
        id: "contractUserType",
        defaultMessage: "User type",
        description: "User type",
    },
    addContract: {
        id: "contract.add",
        defaultMessage: "Add contract",
        description: "Add contract",
    },
    editContract: {
        id: "editContract",
        defaultMessage: "Edit contract",
        description: "Edit contract",
    },
    contractDetails: {
        id: "contract.details",
        defaultMessage: "Contract details",
        description: "Contract details",
    },
    contractCreated: {
        id: "contract.created",
        defaultMessage: "Contract created",
    },
    contractNotFound: {
        id: "contract.message.notFound",
        defaultMessage: "Contract not found",
        description: "Contract not found",
    },
    contractsValidateFetch: {
        id: "contracts.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching contracts",
        description: "Error while fetching contracts",
    },
    contractSuccessfullyDeleted: {
        id: "contract.validation.serviceSuccessfullyDeleted",
        defaultMessage: "Contract successfully deleted",
        description: "Contract successfully deleted",
    },
    contractsValidateDelete: {
        id: "contracts.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete contracts",
        description: "Unable to delete contracts",
    },
    contractSuccessfullyCreated: {
        id: "contract.successfullyCreated",
        defaultMessage: "Contract successfully created",
        description: "Contract successfully created",
    },
    contractSuccessfullyEdited: {
        id: "contract.successfullyEdited",
        defaultMessage: "Contract successfully updated",
        description: "Contract successfully updated",
    },
    contractCreateError: {
        id: "contract.validation.createError",
        defaultMessage: "Unable to create new contract",
        description: "Unable to create new contract",
    },
    contractDetailsValidateFetch: {
        id: "contractDetails.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch requested contract",
        description: "Unable to fetch requested contract",
    },
    contractDetailsValidateDelete: {
        id: "contractDetails.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete selected contract",
        description: "Unable to delete selected contract",
    },
    contractDetailsValidateEdit: {
        id: "contractDetails.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit selected contract",
        description: "Unable to edit selected contract",
    },
    contractUserTypeRequirement: {
        id: "general.validation.contractUserTypeRequirement",
        defaultMessage: "User type is required",
        description: "User type is required",
    },
    offerSuccessfullyCreated: {
        id: "offer.successfullyCreated",
        defaultMessage: "Offer successfully created",
        description: "Offer successfully created",
    },
    offerCreateError: {
        id: "offer.validation.offerCreateError",
        defaultMessage: "Unable to create new offer",
        description: "Unable to create new offer",
    },
    offerNotFound: {
        id: "offer.message.notFound",
        defaultMessage: "Offer not found",
        description: "Offer not found",
    },
    quantity: {
        id: "quantity",
        defaultMessage: "Quantity",
        description: "Quantity",
    },
    requiredQuantity: {
        id: "requiredQuantity",
        defaultMessage: "Quantity is required",
        description: "Quantity is required",
    },
    netPrice: {
        id: "netPrice",
        defaultMessage: "Net price",
        description: "Net price",
    },
    discount: {
        id: "discount",
        defaultMessage: "Discount (%)",
        description: "Discount (%)",
    },
    taxRate: {
        id: "taxRate",
        defaultMessage: "Tax rate (%)",
        description: "Tax rate (%)",
    },
    showTax: {
        id: "showTax",
        defaultMessage: "Show tax",
        description: "Show tax",
    },
    paidState: {
        id: "paidState",
        defaultMessage: "Paid state",
        description: "Paid state",
    },
    paidStateUnpaid: {
        id: "paidState.unpaid",
        defaultMessage: "Unpaid",
        description: "Unpaid",
    },
    paidStatePartiallyPaid: {
        id: "paidState.partiallyPaid",
        defaultMessage: "Partially paid",
        description: "Partially paid",
    },
    paidStateFullyPaid: {
        id: "paidState.fullyPaid",
        defaultMessage: "Fully paid",
        description: "Fully paid",
    },
    researchAndDevelopment: {
        id: "researchAndDevelopment",
        defaultMessage: "Research & Development",
        description: "Research & Development",
    },
    invoiceCountry: {
        id: "invoiceCountry",
        defaultMessage: "Invoice country",
        description: "Invoice country",
    },
    unableToFetchBankStatemens: {
        id: "unableToFetchBankStatements",
        defaultMessage: "Unable to fetch bank statements",
        description: "Unable to fetch bank statements",
    },
    unableToFetchBankStatementDetails: {
        id: "unableToFetchBankStatementDetails",
        defaultMessage: "Unable to fetch bank statement details",
        description: "Unable to fetch bank statement details",
    },
    unableToDeleteBankStatementDetails: {
        id: "unableToDeleteBankStatementDetails",
        defaultMessage: "Unable to delete bank statement",
        description: "Unable to delete bank statement",
    },
    unableToEditBankStatementDetails: {
        id: "unableToEditBankStatementDetails",
        defaultMessage: "Unable to edit bank statement details",
        description: "Unable to edit bank statement details",
    },
    editBankStatement: {
        id: "editBankStatement",
        defaultMessage: "Edit bank statement",
        description: "Edit bank statement",
    },
    editBankStatementDetails: {
        id: "editBankStatementDetails",
        defaultMessage: "Bank statement details",
        description: "Bank statement details",
    },
    uploadedAndParsedBankStatement: {
        id: "uploadedAndParsedBankStatement",
        defaultMessage: "Uploaded and parsed new bank statements",
        description: "Uploaded and parsed new bank statements",
    },
    uploadedAndParsedInvoice: {
        id: "uploadedAndParsedInvoice",
        defaultMessage: "Uploaded and parsed new invoices",
        description: "Uploaded and parsed new invoices",
    },
    bankStatementsSuccessfullySubmitted: {
        id: "bankStatementsSuccessfullySubmitted",
        defaultMessage: "Bank statements successfully submited",
        description: "Bank statements successfully submited",
    },
    invoicesSuccessfullySubmitted: {
        id: "invoicesSuccessfullySubmitted",
        defaultMessage: "Invoices successfully submited",
        description: "Invoices successfully submited",
    },
    unableToUpload: {
        id: "unableToUpload",
        defaultMessage: "Unable to upload",
        description: "Unable to upload",
    },
    bankStatementsLowerCase: {
        id: "bankStatementLowerCase",
        defaultMessage: "bank statements",
        description: "bank statements",
    },
    invoicesLowerCase: {
        id: "invoicesLowerCase",
        defaultMessage: "invoices",
        description: "invoices",
    },
    unableToSubmit: {
        id: "unableToSubmit",
        defaultMessage: "Unable to submit",
        description: "Unable to submit",
    },
    currency: {
        id: "currency",
        defaultMessage: "Currency",
        description: "Currency",
    },
    invoice: {
        id: "invoice",
        defaultMessage: "Invoice",
        description: "Invoice",
    },
    invoiceNumber: {
        id: "invoiceNumber",
        defaultMessage: "Invoice number",
        description: "Invoice number",
    },
    requiredInvoiceNumber: {
        id: "requiredInvoiceNumber",
        defaultMessage: "Invoice number is required",
        description: "Invoice number is required",
    },
    buyer: {
        id: "buyer",
        defaultMessage: "Buyer",
        description: "Buyer",
    },
    requiredBuyer: {
        id: "requiredBuyer",
        defaultMessage: "Buyer is required",
        description: "Buyer is required",
    },
    invoicesValidateFetch: {
        id: "invoices.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching invoices",
        description: "Error while fetching invoices",
    },
    invoiceSuccessfullySaved: {
        id: "invoice.validation.invoiceSuccessfullySaved",
        defaultMessage: "Invoice successfully saved",
        description: "Invoice successfully saved",
    },
    invoiceSuccessfullyCreated: {
        id: "invoice.validation.invoiceSuccessfullyCreated",
        defaultMessage: "Invoice successfully created",
        description: "Invoice successfully created",
    },
    documentClassifications: {
        id: "documentClassifications",
        defaultMessage: "Document classifications",
        description: "Document classifications",
    },
    documentCategories: {
        id: "documentCategories",
        defaultMessage: "Document categories",
        description: "Document categories",
    },
    serviceCategories: {
        id: "serviceCategories",
        defaultMessage: "Service categories",
        description: "Service categories",
    },
    companyName: {
        id: "companyName",
        defaultMessage: "Company name",
        description: "Company name",
    },
    addNewAddress: {
        id: "addNewAddress",
        defaultMessage: "Add new address",
        description: "Add new address",
    },
    VATNumber: {
        id: "VATNumber",
        defaultMessage: "VAT number",
        description: "VAT number",
    },
    requiredVATNumber: {
        id: "requiredVATNumber",
        defaultMessage: "VAT number is required",
        description: "VAT number is required",
    },
    adresses: {
        id: "adresses",
        defaultMessage: "Adresses",
        description: "Adresses",
    },
    requiredAddress: {
        id: "requiredAddress",
        defaultMessage: "Address is required",
        description: "Address is required",
    },
    street: {
        id: "street",
        defaultMessage: "Street",
        description: "Street",
    },
    streetNumber: {
        id: "streetNumber",
        defaultMessage: "Street number",
        description: "Street number",
    },
    isMainAddress: {
        id: "isMainAddress",
        defaultMessage: "Is main address",
        description: "Is main address",
    },
    companyMainAddressLocation: {
        id: "companyMainAddressLocation",
        defaultMessage: "Company main address location",
        description: "Company main address location",
    },
    selectInvoice: {
        id: "selectInvoice",
        defaultMessage: "Select invoice",
        description: "Select invoice",
    },
    saveBankStatement: {
        id: "saveBankStatement",
        defaultMessage: "Save bank statements",
        description: "Save bank statements",
    },
    IBANs: {
        id: "IBANs",
        defaultMessage: "IBAN(s)",
        description: "IBAN(s)",
    },
    bankStatement: {
        id: "bankStatement",
        defaultMessage: "Bank statement",
        description: "Bank statement",
    },
    selectBankStatement: {
        id: "selectBankStatement",
        defaultMessage: "Select bank statement",
        description: "Select bank statement",
    },
    invoiceCategory: {
        id: "invoiceCategory",
        defaultMessage: "Invoice category",
        description: "Invoice category",
    },
    addInvoiceCategory: {
        id: "addInvoiceCategory",
        defaultMessage: "Add invoice category",
        description: "Add invoice category",
    },
    saveInvoices: {
        id: "saveInvoices",
        defaultMessage: "Save invoices",
        description: "Save invoices",
    },
    bankStatementNotFound: {
        id: "bankStatementNotFound",
        defaultMessage: "Bank statement not found",
        description: "Bank statement not found",
    },
    invoiceNotFound: {
        id: "invoiceNotFound",
        defaultMessage: "Invoice not found",
        description: "Invoice not found",
    },
    bankStatementDetails: {
        id: "bankStatementDetails",
        defaultMessage: "Bank statement details",
        description: "Bank statement details",
    },
    bankStatementTypeIncoming: {
        id: "bankStatementType.incoming",
        defaultMessage: "incoming",
        description: "incoming",
    },
    bankStatementTypeOutgoing: {
        id: "bankStatementType.outgoing",
        defaultMessage: "outgoing",
        description: "outgoing",
    },
    saveChanges: {
        id: "general.actions.saveChanges",
        defaultMessage: "Save changes",
        description: "Save changes",
    },
    changesSaved: {
        id: "general.actions.changesSaved",
        defaultMessage: "Changes saved successfully",
        description: "Changes saved successfully",
    },
    delete: {
        id: "general.actions.delete",
        defaultMessage: "Delete",
        description: "Delete",
    },
    remove: {
        id: "general.actions.remove",
        defaultMessage: "Remove",
        description: "Remove",
    },
    cancel: {
        id: "general.actions.cancel",
        defaultMessage: "Cancel",
        description: "Cancel",
    },
    add: {
        id: "general.actions.add",
        defaultMessage: "Add",
        description: "Add",
    },
    confirm: {
        id: "general.actions.confirm",
        defaultMessage: "Confirm",
        description: "Confirm",
    },
    edit: {
        id: "general.actions.edit",
        defaultMessage: "Edit",
        description: "Edit",
    },
    back: {
        id: "general.actions.back",
        defaultMessage: "Back",
        description: "Back",
    },
    yes: {
        id: "general.actions.yes",
        defaultMessage: "Yes",
        description: "Yes",
    },
    no: {
        id: "general.actions.no",
        defaultMessage: "No",
        description: "No",
    },
    activate: {
        id: "general.actions.activate",
        defaultMessage: "Activate",
        description: "Activate",
    },
    deactivate: {
        id: "general.actions.deactivate",
        defaultMessage: "Deactivate",
        description: "Deactivate",
    },
    noResults: {
        id: "general.noResults",
        defaultMessage: "No results found",
        description: "No results found",
    },
    title: {
        id: "general.title",
        defaultMessage: "Title",
        description: "Title",
    },
    buyRequestDetails: {
        id: "buyRequests.table.details",
        defaultMessage: "Buy request details",
        description: "Buy request details",
    },
    created: {
        id: "general.created",
        defaultMessage: "Created",
        description: "Created",
    },
    createdBy: {
        id: "general.createdBy",
        defaultMessage: "Created by",
        description: "Created by",
    },
    create: {
        id: "general.create",
        defaultMessage: "Create",
        description: "Create",
    },
    remote: {
        id: "general.remote",
        defaultMessage: "Remote",
        description: "Remote",
    },
    remoteWork: {
        id: "general.remoteWork",
        defaultMessage: "Remote work",
        description: "Remote work",
    },
    vacation: {
        id: "general.vacation",
        defaultMessage: "Vacation",
        description: "Vacation",
    },
    sickLeave: {
        id: "general.sickLeave",
        defaultMessage: "Sick leave",
        description: "Sick leave",
    },
    unpaidLeave: {
        id: "general.unpaidLeave",
        defaultMessage: "Unpaid leave",
        description: "Unpaid leave",
    },
    paidLeave: {
        id: "general.paidLeave",
        defaultMessage: "Paid leave",
        description: "Paid leave",
    },
    parentalLeave: {
        id: "general.parentalLeave",
        defaultMessage: "Parental leave",
        description: "Parental leave",
    },
    yearlyVacationPlan: {
        id: "general.yearlyVacationPlan",
        defaultMessage: "Yearly vacation plan",
        description: "Yearly vacation plan",
    },
    usersWithoutYearlyVacationPlan: {
        id: "general.usersWithoutYearlyVacationPlan",
        defaultMessage: "Users without yearly vacation plan",
        description: "Users without yearly vacation plan",
    },
    office: {
        id: "general.office",
        defaultMessage: "Office",
        description: "Office",
    },
    present: {
        id: "general.present",
        defaultMessage: "Present",
        description: "Present",
    },
    absent: {
        id: "general.absent",
        defaultMessage: "Absent",
        description: "Absent",
    },
    weekend: {
        id: "general.weekend",
        defaultMessage: "Weekend",
        description: "Weekend",
    },
    unknown: {
        id: "general.unknown",
        defaultMessage: "Unknown",
        description: "Unknown",
    },
    AllPersonnel: {
        id: "All personnel",
        defaultMessage: "All personnel",
        description: "All personnel",
    },
    Staff: {
        id: "Staff",
        defaultMessage: "Staff",
        description: "Staff",
    },
    UpcomingBirthdays: {
        id: "Upcoming birthdays",
        defaultMessage: "Upcoming birthdays",
        description: "Upcoming birthdays",
    },
    Vacation: {
        id: "Vacation",
        defaultMessage: "Vacation",
        description: "Vacation",
    },
    Remote: {
        id: "Remote",
        defaultMessage: "Remote",
        description: "Remote",
    },
    SickLeave: {
        id: "Sick leave",
        defaultMessage: "Sick leave",
        description: "Sick leave",
    },
    PaidLeave: {
        id: "Paid leave",
        defaultMessage: "Paid leave",
        description: "Paid leave",
    },
    ParentalLeave: {
        id: "Parental leave",
        defaultMessage: "Parental leave",
        description: "Parental leave",
    },
    YearlyVacationPlan: {
        id: "Yearly vacation plan",
        defaultMessage: "Yearly vacation plan",
        description: "Yearly vacation plan",
    },
    WorkingPlans: {
        id: "Working plans",
        defaultMessage: "Working plans",
        description: "Working plans",
    },
    LocoRides: {
        id: "Loco Rides",
        defaultMessage: "Loco Rides",
        description: "Loco Rides",
    },
    Company: {
        id: "Company",
        defaultMessage: "Company",
        description: "Company",
    },
    Contracts: {
        id: "Contracts",
        defaultMessage: "Contracts",
        description: "Contracts",
    },
    Banks: {
        id: "Banks",
        defaultMessage: "Banks",
        description: "Banks",
    },
    Accounts: {
        id: "Accounts",
        defaultMessage: "Accounts",
        description: "Accounts",
    },
    CompanyContacts: {
        id: "Company contacts",
        defaultMessage: "Company contacts",
        description: "Company contacts",
    },
    CompanyDetails: {
        id: "Company details",
        defaultMessage: "Company details",
        description: "Company details",
    },
    AdministrativeSettings: {
        id: "Administrative settings",
        defaultMessage: "Administrative settings",
        description: "Administrative settings",
    },
    ProjectRoles: {
        id: "Project roles",
        defaultMessage: "Project roles",
        description: "Project roles",
    },
    FinanceSettings: {
        id: "Finance settings",
        defaultMessage: "Finance settings",
        description: "Finance settings",
    },
    addBuyRequest: {
        id: "buyRequests.table.addBuyRequest",
        defaultMessage: "Add buy request",
        description: "Add buy request",
    },
    editBuyRequest: {
        id: "buyRequests.table.editBuyRequest",
        defaultMessage: "Edit buy request",
        description: "Edit buy request",
    },
    approveBuyRequest: {
        id: "buyRequests.table.approveBuyRequest",
        defaultMessage: "Approve buy request",
        description: "Approve buy request",
    },
    rejectBuyRequest: {
        id: "buyRequests.table.rejectBuyRequest",
        defaultMessage: "Reject buy request",
        description: "Reject buy request",
    },
    rejectReason: {
        id: "buyRequests.table.rejectReason",
        defaultMessage: "Reject reason",
        description: "Reject reason",
    },
    purchaseAssignee: {
        id: "buyRequests.table.purchaseAssignee",
        defaultMessage: "Purchase assignee",
        description: "Purchase assignee",
    },
    buyRequestValidateNotFound: {
        id: "buyRequests.validation.notFound",
        defaultMessage: "Buy request not found",
        description: "Buy request not found",
    },
    buyRequestValidateFetch: {
        id: "buyRequests.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching buy requests",
        description: "Error while fetching buy requests",
    },
    buyRequestValidateFetchDetails: {
        id: "buyRequests.validation.unsuccessfulDetailsFetch",
        defaultMessage: "Unable to fetch buy request details",
        description: "Unable to fetch buy request details",
    },
    buyRequestValidateDelete: {
        id: "buyRequests.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete buy request",
        description: "Unable to delete buy request",
    },
    buyRequestValidateProcess: {
        id: "buyRequests.validation.unsuccessfulProcess",
        defaultMessage: "Unable to process buy request",
        description: "Unable to process buy request",
    },
    buyRequestValidateEdit: {
        id: "buyRequests.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit buy request details",
        description: "Unable to edit buy request details",
    },
    buyRequestValidateAdd: {
        id: "buyRequests.validation.unsuccessfulAdd",
        defaultMessage: "Unable to add new buy request",
        description: "Unable to add new buy request",
    },
    buyRequestSuccessfullyDeleted: {
        id: "buyRequest.validation.successfullyDeleted",
        defaultMessage: "Buy request successfully deleted",
        description: "Buy request successfully deleted",
    },
    buyRequestsSuccessfullyDeleted: {
        id: "buyRequests.validation.successfullyDeleted",
        defaultMessage: "Buy requests successfully deleted",
        description: "Buy request successfully deleted",
    },
    buyRequestSuccessfullyProcessed: {
        id: "buyRequest.validation.successfullyProcessed",
        defaultMessage: "Buy request successfully processed",
        description: "Buy request successfully processed",
    },
    buyRequestsSuccessfullyProcessed: {
        id: "buyRequests.validation.successfullyProcessed",
        defaultMessage: "Buy requests successfully processed",
        description: "Buy request successfully processed",
    },
    processed: {
        id: "invoices.processed",
        defaultMessage: "Processed",
        description: "Processed",
    },
    notProcessed: {
        id: "invoices.notProcessed",
        defaultMessage: "Not processed",
        description: "Not processed",
    },
    buyRequestSuccessfullyCreated: {
        id: "buyRequests.validation.successfullyCreated",
        defaultMessage: "Buy request successfully created",
        description: "Buy request successfully created",
    },
    buyRequestSuccessfullyUpdated: {
        id: "buyRequests.validation.successfullyUpdated",
        defaultMessage: "Buy request successfully updated",
        description: "Buy request successfully updated",
    },
    buyRequestConfirmDelete: {
        id: "buyRequests.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this buy request?",
        description: "Are you sure you want to delete this buy request?",
    },
    workAttendances: {
        id: "workAttendances.table.workAttendances",
        defaultMessage: "Work attendances",
        description: "Work attendances",
    },
    requestDelete: {
        id: "workAttendances.requestDelete",
        defaultMessage: "Request delete",
        description: "Request delete",
    },
    approveDelete: {
        id: "workAttendances.approveDelete",
        defaultMessage: "Approve delete",
        description: "Approve delete",
    },
    closeSickLeave: {
        id: "workAttendances.closeSickLeave",
        defaultMessage: "Set sick leave end date to today",
        description: "Set sick leave end date to today",
    },
    startSickLeave: {
        id: "workAttendances.startSickLeave",
        defaultMessage: "Start sick leave",
        description: "Start sick leave",
    },
    startParentalLeave: {
        id: "workAttendances.startParentalLeave",
        defaultMessage: "Start parental leave",
        description: "Start parental leave",
    },
    datesValidateFetch: {
        id: "workAttendances.validation.unsuccessfulDatesFetch",
        defaultMessage: "Unable to fetch previous dates",
        description: "Unable to fetch previous dates",
    },
    workAttendanceValidateEdit: {
        id: "workAttendances.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit work attendance",
        description: "Unable to edit work attendance",
    },
    workAttendanceValidateDelete: {
        id: "workAttendances.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete work attendance",
        description: "Unable to delete work attendance",
    },
    workAttendanceValidateProcess: {
        id: "workAttendances.validation.unsuccessfulProcess",
        defaultMessage: "Unable to process work attendance",
        description: "Unable to process work attendance",
    },
    workAttendanceCreateError: {
        id: "workAttendances.validation.createError",
        defaultMessage: "Error while creating work attendance",
        description: "Error while creating work attendance",
    },
    workAttendanceValidateFetch: {
        id: "workAttendances.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching work attendances",
        description: "Error while fetching work attendances",
    },
    homePageDataFetch: {
        id: "homePage.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching home page data",
        description: "Error while fetching home page data",
    },
    workAttendanceSelectedByOther: {
        id: "workAttendances.legend.SelectedByOtherWorkAttendances",
        defaultMessage: "Selected by other work attendances",
        description: "Selected by other work attendances",
    },
    workAttendancePreviouslySelected: {
        id: "workAttendances.legend.previously.selected",
        defaultMessage: "Previously selected",
        description: "Previously selected",
    },
    workAttendanceCurrentlySelected: {
        id: "workAttendances.legend.currently.selected",
        defaultMessage: "Currently selected",
        description: "Currently selected",
    },
    vacationDaysValidate: {
        id: "workAttendances.validation.vacationDaysValidate",
        defaultMessage: "You have selected more vacation days than you have available",
        description: "You have selected more vacation days than you have available",
    },
    workAttendanceSuccessfullyDeleted: {
        id: "workAttendances.validation.successfullyDeleted",
        defaultMessage: "Work attendace successfully deleted",
        description: "Work attendace successfully deleted",
    },
    workAttendanceSuccessfullyProcessed: {
        id: "workAttendances.validation.successfullyProcessed",
        defaultMessage: "Work attendance successfully processed",
        description: "Work attendance successfully processed",
    },
    editingWorkAttendance: {
        id: "workAttendances.general.editing",
        defaultMessage: "Editing work attendance:",
        description: "Editing work attendance:",
    },
    creatingWorkAttendance: {
        id: "workAttendances.general.creating",
        defaultMessage: "Creating work attendance:",
        description: "Creating work attendance:",
    },
    approveWorkAttendance: {
        id: "workAttendances.table.approveWorkAttendance",
        defaultMessage: "Approve work attendance",
        description: "Approve work attendance",
    },
    rejectWorkAttendance: {
        id: "workAttendances.table.rejectWorkAttendance",
        defaultMessage: "Reject work attendance",
        description: "Reject work attendance",
    },
    reasonForRemoteRequest: {
        id: "workAttendances.general.reasonForRemoteRequest",
        defaultMessage: "Reason for remote request",
        description: "Reason for remote request",
    },
    usersValidateFetch: {
        id: "users.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching users",
        description: "Error while fetching users",
    },
    allPersonnel: {
        id: "users.general.allPersonnel",
        defaultMessage: "All personnel",
        description: "All personnel",
    },
    userDetails: {
        id: "users.general.userDetails",
        defaultMessage: "User details",
        description: "User details",
    },
    staff: {
        id: "users.general.staff",
        defaultMessage: "Staff",
        description: "Staff",
    },
    atWork: {
        id: "atWork",
        defaultMessage: "At work",
        description: "At work",
    },
    vacationDetails: {
        id: "users.table.vacationDetails",
        defaultMessage: "Vacation details",
        description: "Vacation details",
    },
    addUser: {
        id: "users.table.addUser",
        defaultMessage: "Add user",
        description: "Add user",
    },
    deleteUser: {
        id: "users.table.deleteUser",
        defaultMessage: "Delete user",
        description: "Delete user",
    },
    workingPlanNew: {
        id: "workingPlanNew",
        defaultMessage: "has just added a new working plan",
    },
    deletedApprovedWorkingPlan: {
        id: "deletedApprovedWorkingPlan",
        defaultMessage: "has just deleted their working plan",
        description: "has just deleted their working plan",
    },
    addSuperAdmin: {
        id: "users.table.addSuperAdmin",
        defaultMessage: "Add super admin",
        description: "Add SuperAdmin",
    },
    contractDays: {
        id: "users.table.contractDays",
        defaultMessage: "Contract days",
        description: "Contract days",
    },
    correctionDays: {
        id: "users.table.correctionDays",
        defaultMessage: "Correction days",
        description: "Correction days",
    },
    oldDays: {
        id: "users.table.oldDays",
        defaultMessage: "Old days",
        description: "Old days",
    },
    daysUsed: {
        id: "users.table.daysUsed",
        defaultMessage: "Days used",
        description: "Days used",
    },
    validateUsername: {
        id: "users.validation.validateUsername",
        defaultMessage: "Username is required.",
        description: "Username is required.",
    },
    exampleUsername: {
        id: "users.validation.exampleUsername",
        defaultMessage: "Invalid username. E.g. example@email.com",
        description: "Invalid username. E.g. example@email.com",
    },
    validateFirstName: {
        id: "users.validation.validateFirstName",
        defaultMessage: "First name is required.",
        description: "First name is required.",
    },
    validatePassword: {
        id: "users.validation.validatePassword",
        defaultMessage: "Password is required.",
        description: "Password is required.",
    },
    validatePasswordNew: {
        id: "users.validation.validatePasswordNew",
        defaultMessage: "New password is required.",
        description: "New password is required.",
    },
    validateLastName: {
        id: "users.validation.validateLastName",
        defaultMessage: "Last name is required.",
        description: "Last name is required.",
    },
    validateJobTitle: {
        id: "users.validation.validateJobTitle",
        defaultMessage: "Job title is required.",
        description: "Job title is required.",
    },
    validateRoles: {
        id: "users.validation.validateRoles",
        defaultMessage: "At least one role is required.",
        description: "At least one role is required.",
    },
    userSuccessfullyCreated: {
        id: "users.validation.userSuccessfullyCreated",
        defaultMessage: "User successfully created",
        description: "User successfully created",
    },
    userValidateAdd: {
        id: "users.validation.userValidateAdd",
        defaultMessage: "Unable to add new user",
        description: "Unable to add new user",
    },
    userDetailsValidateFetch: {
        id: "users.validation.userDetailsValidateFetch",
        defaultMessage: "Unable to fetch user details",
        description: "Unable to fetch user details",
    },
    userDetailsValidateEdit: {
        id: "users.validation.userDetailsValidateEdit",
        defaultMessage: "Unable to edit user details",
        description: "Unable to edit user details",
    },
    vacationDetailsValidateFetch: {
        id: "users.validation.vacationDetailsValidateFetch",
        defaultMessage: "Unable to fetch vacation details",
        description: "Unable to fetch vacation details",
    },
    vacationDetailsUpdated: {
        id: "users.validation.vacationDetailsUpdated",
        defaultMessage: "Vacation details updated",
        description: "Vacation details updated",
    },
    vacationDetailsUnsuccessfulUpdate: {
        id: "users.validation.vacationDetailsUnsuccessfulUpdate",
        defaultMessage: "Could not update vacation details",
        description: "Could not update vacation details",
    },
    invalidPhoneNumber: {
        id: "users.validation.invalidPhoneNumber",
        defaultMessage: "Invalid phone number.",
        description: "Invalid phone number.",
    },
    invalidOib: {
        id: "users.validation.invalidOib",
        defaultMessage: "Invalid oib.",
        description: "Invalid oib.",
    },
    userValidateNotFound: {
        id: "users.validation.userValidateNotFound",
        defaultMessage: "User not found",
        description: "User not found",
    },
    imageValidateFetch: {
        id: "users.validation.imageValidateFetch",
        defaultMessage: "Error while fetching image",
        description: "Error while fetching image",
    },
    imageValidateUpload: {
        id: "users.validation.imageValidateUpload",
        defaultMessage: "Error while uploading image",
        description: "Error while uploading image",
    },
    addNewUser: {
        id: "users.form.addNewUser",
        defaultMessage: "Add new user",
        description: "Add new user",
    },
    addNewSuperAdmin: {
        id: "users.form.addNewSuperAdmin",
        defaultMessage: "Add new super admin",
        description: "Add new SuperAdmin",
    },
    userCreated: {
        id: "users.form.userCreated",
        defaultMessage: "User created",
        description: "User created",
    },
    personalDetails: {
        id: "users.form.personalDetails",
        defaultMessage: "Personal details",
        description: "Personal details",
    },
    access: {
        id: "users.form.access",
        defaultMessage: "Access",
        description: "Access",
    },
    personalStatement: {
        id: "users.form.personalStatement",
        defaultMessage: "Personal statement",
        description: "Personal statement",
    },
    languages: {
        id: "users.form.languages",
        defaultMessage: "Languages",
        description: "Languages",
    },
    dateOfBirth: {
        id: "users.form.dateOfBirth",
        defaultMessage: "Date of birth",
        description: "Date of birth",
    },
    country: {
        id: "users.form.country",
        defaultMessage: "Country",
        description: "Country",
    },
    city: {
        id: "users.form.city",
        defaultMessage: "City",
        description: "City",
    },
    priorWorkExperience: {
        id: "users.form.priorWorkExperience",
        defaultMessage: "Prior work experience",
        description: "Prior work experience",
    },
    academicTitle: {
        id: "users.form.academicTitle",
        defaultMessage: "Academic title",
        description: "Academic title",
    },
    company: {
        id: "users.form.company",
        defaultMessage: "Company",
        description: "Company",
    },
    companyCreateError: {
        id: "company.create.error",
        defaultMessage: "Error while creating company",
        description: "Error while creating company",
    },
    companyNameValidate: {
        id: "company.neme.validate",
        defaultMessage: "Company name is required",
        description: "Company name is required",
    },
    companyContacts: {
        id: "company.contacts",
        defaultMessage: "Company contacts",
        description: "Company contacts",
    },
    newAddress: {
        id: "company.new.address",
        defaultMessage: "Added new address",
        description: "Added new address",
    },
    terminal: {
        id: "company.terminal",
        defaultMessage: "Terminal",
        description: "Terminal",
    },
    officeSpace: {
        id: "company.officeSpace",
        defaultMessage: "Office space",
        description: "Office space",
    },
    newAddressError: {
        id: "company.new.address.error",
        defaultMessage: "Error while creating new address",
        description: "Error while creating new address",
    },
    invalidVAT: {
        id: "invalid.VAT.number",
        defaultMessage: "Invalid VAT number",
        description: "Invalid VAT number",
    },
    streetAddressRequired: {
        id: "street.address.required",
        defaultMessage: "Street is required.",
        description: "Street is required.",
    },
    streetNumberRequired: {
        id: "street.number.required",
        defaultMessage: "Number is required.",
        description: "Number is required.",
    },
    companyValidateFetch: {
        id: "company.validation.fetch",
        defaultMessage: "Error while fetching company",
        description: "Error while fetching company",
    },
    unableToEditCompany: {
        id: "company.unabled.editing",
        defaultMessage: "Unable to edit company details",
        description: "Unable to edit company details",
    },
    jobDescription: {
        id: "users.form.jobDescription",
        defaultMessage: "Job description",
        description: "Job Description",
    },
    jobRequirements: {
        id: "users.form.jobRequirements",
        defaultMessage: "Job requirements",
        description: "Job requirements",
    },
    userProjects: {
        id: "users.form.userProjects",
        defaultMessage: "Projects",
        description: "User projects",
    },
    project: {
        id: "project",
        defaultMessage: "Project",
        description: "Project",
    },
    workExperience: {
        id: "users.form.workExperience",
        defaultMessage: "Work experience at the company",
        description: "Work experience at the company",
    },
    activeDirectoryUsername: {
        id: "users.form.activeDirectoryUsername",
        defaultMessage: "Active Directory username",
        description: "Active Directory Username",
    },
    guid: {
        id: "users.form.guid",
        defaultMessage: "Guid",
        description: "Guid",
    },
    uploadPicture: {
        id: "users.actions.uploadPicture",
        defaultMessage: "Upload picture",
        description: "Upload picture",
    },
    addNewPicture: {
        id: "users.actions.addNewPicture",
        defaultMessage: "Add new picture",
        description: "Add new picture",
    },
    backToHomepage: {
        id: "general.actions.backToHomepage",
        defaultMessage: "Back to homepage",
        description: "Back to homepage",
    },
    editUserDetails: {
        id: "general.actions.editUserDetails",
        defaultMessage: "Edit user details",
        description: "Edit user details",
    },
    continueEditingUserDetails: {
        id: "general.actions.continueEditingUserDetails",
        defaultMessage: "User successfully created. Do you want to continue editing user details?",
        description: "User successfully created. Do you want to continue editing user details?",
    },
    redirectToHomePage: {
        id: "general.actions.redirectToHomePage",
        defaultMessage: "You will be automatically redirected to the home page in",
        description: "You will be automatically redirected to the home page in",
    },
    pickAPassword: {
        id: "general.actions.pickAPassword",
        defaultMessage: "Pick a password",
        description: "Pick a password",
    },
    atLeastOneLowercase: {
        id: "general.password.atLeastOneLowercase",
        defaultMessage: "At least one lowercase",
        description: "At least one lowercase",
    },
    atLeastOneUppercase: {
        id: "general.password.atLeastOneUppercase",
        defaultMessage: "At least one uppercase",
        description: "At least one uppercase",
    },
    atLeastOneNumeric: {
        id: "general.password.atLeastOneNumeric",
        defaultMessage: "At least one numeric",
        description: "At least one numeric",
    },
    atLeastOneSpecialCharacter: {
        id: "general.password.atLeastOneSpecialCharacter",
        defaultMessage: "At least one special character",
        description: "At least one special character",
    },
    minimumCharacters: {
        id: "general.password.minimumCharacters",
        defaultMessage: "Minimum 8 characters",
        description: "Minimum 8 characters",
    },
    passwordPrompt: {
        id: "general.password.passwordPrompt",
        defaultMessage: "Please enter a password",
        description: "Please enter a password",
    },
    weakLabel: {
        id: "general.password.weakLabel",
        defaultMessage: "Weak",
        description: "Weak",
    },
    mediumLabel: {
        id: "general.password.mediumLabel",
        defaultMessage: "Medium",
        description: "Medium",
    },
    strongLabel: {
        id: "general.password.strongLabel",
        defaultMessage: "Strong",
        description: "Strong",
    },
    year: {
        id: "general.year",
        defaultMessage: "Year",
        description: "Year",
    },
    cardNumber: {
        id: "general.cardNumber",
        defaultMessage: "Card number",
        description: "Card number",
    },
    gitlabUsername: {
        id: "general.gitlabUsername",
        defaultMessage: "Gitlab username",
        description: "Gitlab username",
    },
    clickupUsername: {
        id: "general.clickupUsername",
        defaultMessage: "Clickup username",
        description: "Clickup username",
    },
    jobTitle: {
        id: "general.jobTitle",
        defaultMessage: "Job title",
        description: "Job title",
    },
    email: {
        id: "general.email",
        defaultMessage: "Email",
        description: "Email",
    },
    phoneNumber: {
        id: "general.phoneNumber",
        defaultMessage: "Phone number",
        description: "Phone number",
    },
    oib: {
        id: "general.oib",
        defaultMessage: "OIB",
        description: "OIB",
    },
    address: {
        id: "general.address",
        defaultMessage: "Address",
        description: "Address",
    },
    zipCode: {
        id: "general.zipCode",
        defaultMessage: "Zip code",
        description: "Zip code",
    },
    password: {
        id: "general.password",
        defaultMessage: "Password",
        description: "Password",
    },
    remoteEnabled: {
        id: "general.remoteEnabled",
        defaultMessage: "Remote enabled",
        description: "Remote enabled",
    },
    employeesViewEnabled: {
        id: "general.employeesViewEnabled",
        defaultMessage: "Employees view enabled",
        description: "Employees view enabled",
    },
    employmentType: {
        id: "general.employmentType",
        defaultMessage: "Employment type",
        description: "Employment type",
    },
    weeklyHours: {
        id: "general.weeklyHours",
        defaultMessage: "Weekly hours",
        description: "Weekly hours",
    },
    userInfo: {
        id: "general.userInfo",
        defaultMessage: "User info",
        description: "User info",
    },
    gender: {
        id: "general.gender",
        defaultMessage: "Gender",
        description: "Gender",
    },
    tShirtSize: {
        id: "general.tShirtSize",
        defaultMessage: "T-shirt size",
        description: "T-shirt size",
    },
    passwordsCantMatch: {
        id: "general.passwordsCantMatch",
        defaultMessage: "New password can't be the same as old",
        description: "New password can't be the same as old",
    },
    passwordsDontMatch: {
        id: "general.passwordsDontMatch",
        defaultMessage: "Passwords don't match",
        description: "Passwords don't match",
    },
    changePassword: {
        id: "general.changePassword",
        defaultMessage: "Change password",
        description: "Change password",
    },
    passwordNew: {
        id: "general.passwordNew",
        defaultMessage: "New password",
        description: "New password",
    },
    firstName: {
        id: "general.firstName",
        defaultMessage: "First name",
        description: "First name",
    },
    lastName: {
        id: "general.lastName",
        defaultMessage: "Last name",
        description: "Last name",
    },
    username: {
        id: "general.username",
        defaultMessage: "Username",
        description: "Username",
    },
    reasonForPaidLeaveRequest: {
        id: "workAttendances.general.reasonForPaidLeaveRequest",
        defaultMessage: "Reason for paid leave request",
        description: "Reason for paid leave request",
    },
    approveStatus: {
        id: "general.approveStatus",
        defaultMessage: "Approve status",
        description: "Approve status",
    },
    dragAndDropFiles: {
        id: "general.dragAndDropFiles",
        defaultMessage: "Drag and drop files here to upload.",
        description: "Drag and drop files here to upload.",
    },
    vacationDaysLeft: {
        id: "general.vacationDaysLeft",
        defaultMessage: "Vacation days left :",
        description: "Vacation days left :",
    },
    startDate: {
        id: "general.startDate",
        defaultMessage: "Start date",
        description: "Start date",
    },
    endDate: {
        id: "general.endDate",
        defaultMessage: "End date",
        description: "End date",
    },
    deliveryDate: {
        id: "general.deliveryDate",
        defaultMessage: "Delivery date",
        description: "Delivery date",
    },
    totalDays: {
        id: "general.totalDays",
        defaultMessage: "Total Days",
        description: "Total Days",
    },
    lastUpdated: {
        id: "general.lastUpdated",
        defaultMessage: "Last updated",
        description: "Last updated",
    },
    selectDates: {
        id: "general.selectDates",
        defaultMessage: "Select dates",
        description: "Select dates",
    },
    requiredReason: {
        id: "general.requiredReason",
        defaultMessage: "Reason is required.",
        description: "Reason is required.",
    },
    estimatedValue: {
        id: "general.estimatedValue",
        defaultMessage: "Estimated Value",
        description: "Estimated Value",
    },
    reasonForPurchase: {
        id: "general.reasonForPurchase",
        defaultMessage: "Reason for purchase",
        description: "Reason for purchase",
    },
    requiredTitle: {
        id: "general.validation.requiredTitle",
        defaultMessage: "Title is required",
        description: "Title is required",
    },
    requested: {
        id: "general.requested",
        defaultMessage: "Requested",
        description: "Requested",
    },
    Requested: {
        id: "Requested",
        defaultMessage: "Requested",
        description: "Requested",
    },
    approved: {
        id: "general.approved",
        defaultMessage: "Approved",
        description: "Approved",
    },
    Approved: {
        id: "Approved",
        defaultMessage: "Approved",
        description: "Approved",
    },
    approve: {
        id: "general.approve",
        defaultMessage: "Approve",
        description: "Approve",
    },
    rejected: {
        id: "general.rejected",
        defaultMessage: "Rejected",
        description: "Rejected",
    },
    Rejected: {
        id: "Rejected",
        defaultMessage: "Rejected",
        description: "Rejected",
    },
    reject: {
        id: "general.reject",
        defaultMessage: "Reject",
        description: "Reject",
    },
    draft: {
        id: "general.draft",
        defaultMessage: "Draft",
        description: "Draft",
    },
    userCancelled: {
        id: "general.userCancelled",
        defaultMessage: "User cancelled",
        description: "User cancelled",
    },
    inReview: {
        id: "general.inReview",
        defaultMessage: "In review",
        description: "In review",
    },
    postponed: {
        id: "general.postponed",
        defaultMessage: "Postponed",
        description: "Postponed",
    },
    completed: {
        id: "general.completed",
        defaultMessage: "Completed",
        description: "Completed",
    },
    deleteRequested: {
        id: "general.deleteRequested",
        defaultMessage: "Delete requested",
        description: "Delete requested",
    },
    all: {
        id: "general.all",
        defaultMessage: "All",
        description: "All",
    },
    reports: { id: "Reports", defaultMessage: "Reports", description: "Reports" },
    points: {
        id: "points",
        defaultMessage: "Points",
        description: "Points",
    },
    negativePoints: {
        id: "negativePoints",
        defaultMessage: "Negative points",
        description: "Negative points",
    },
    reportValidateFetch: {
        id: "reports.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch report details",
        description: "Unable to fetch report details",
    },
    reportSuccessfullyDeleted: {
        id: "reports.validation.successfullyDeleted",
        defaultMessage: "Report successfully deleted",
        description: "Report successfully deleted",
    },
    reportValidateDelete: {
        id: "reports.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete report",
        description: "Unable to delete report",
    },
    reportsValidateFetch: {
        id: "reports.validation.unsuccessfulFetchReports",
        defaultMessage: "Unable to fetch reports",
        description: "Unable to fetch reports",
    },
    reportSuccessfullySubmitted: {
        id: "reports.validation.successfullySubmitted",
        defaultMessage: "Report successfully submitted",
        description: "Report successfully submitted",
    },
    reportValidateAdd: {
        id: "reports.validation.unsuccessfulAdd",
        defaultMessage: "Unable to add new report",
        description: "Unable to add new report",
    },
    reportValidateEdit: {
        id: "reports.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit report details",
        description: "Unable to edit report details",
    },
    editReport: {
        id: "reports.edit",
        defaultMessage: "Edit report",
        description: "Edit report",
    },
    reportNotFound: {
        id: "reports.validation.notFound",
        defaultMessage: "Report not found",
        description: "Report not found",
    },
    reportDetails: {
        id: "reports.table.reportDetails",
        defaultMessage: "Report details",
        description: "Report details",
    },
    ticketUrl: {
        id: "reports.details.ticketUrl",
        defaultMessage: "Ticket url",
        description: "Ticket url",
    },
    commitUrl: {
        id: "reports.details.commitUrl",
        defaultMessage: "Commit url",
        description: "Commit url",
    },
    billableHours: {
        id: "reports.details.billableHours",
        defaultMessage: "Billable hours",
        description: "Billable hours",
    },
    differentCustomerDescription: {
        id: "reports.details.differentCustomerDescription",
        defaultMessage: "Different customer description",
        description: "Different customer description",
    },
    customerDescription: {
        id: "reports.details.customerDescription",
        defaultMessage: "Customer description",
        description: "Customer description",
    },
    hoursRequired: {
        id: "reports.details.hoursRequired",
        defaultMessage: "Hours is required",
        description: "Hours is required",
    },
    correctHoursRequired: {
        id: "reports.details.correctHoursRequired",
        defaultMessage: "Correct hours required",
        description: "Correct hours required",
    },
    categoryRequired: {
        id: "reports.details.categoryRequired",
        defaultMessage: "Category is required",
        description: "Category is required",
    },
    descriptionRequired: {
        id: "reports.details.descriptionRequired",
        defaultMessage: "Description is required",
        description: "Description is required",
    },
    textRequired: {
        id: "reports.details.textRequired",
        defaultMessage: "Text is required",
        description: "Text is required",
    },
    workingPlansValidateFetch: {
        id: "workingPlans.validation.workingPlansValidateFetch",
        defaultMessage: "Error while fetching working plans",
        description: "Error while fetching working plans",
    },
    workingPlansSuccessfullyDeleted: {
        id: "workingPlans.validation.workingPlansSuccessfullyDeleted",
        defaultMessage: "Working plan successfully deleted",
        description: "Working plan successfully deleted",
    },
    workingPlansValidateDelete: {
        id: "workingPlans.validation.workingPlansValidateDelete",
        defaultMessage: "Unable to delete working plan",
        description: "Unable to delete working plan",
    },
    workingPlansSuccessfullySubmitted: {
        id: "workingPlans.validation.workingPlansSuccessfullySubmitted",
        defaultMessage: "Working plan successfully submitted",
        description: "Working plan successfully submitted",
    },
    workingPlansValidateSubmit: {
        id: "workingPlans.validation.workingPlansValidateSubmit",
        defaultMessage: "Unable to submit working plan",
        description: "Unable to submit working plan",
    },
    workingPlansSuccessfullyProcessed: {
        id: "workingPlans.validation.workingPlansSuccessfullyProcessed",
        defaultMessage: "Working plans successfully processed",
        description: "Working plans successfully processed",
    },
    workingPlanSuccessfullyProcessed: {
        id: "workingPlan.validation.workingPlanSuccessfullyProcessed",
        defaultMessage: "Working plan successfully processed",
        description: "Working plan successfully processed",
    },
    workingPlansValidateProcess: {
        id: "workingPlans.validation.workingPlansValidateProcess",
        defaultMessage: "Unable to process working plan",
        description: "Unable to process working plan",
    },
    approveWorkingPlan: {
        id: "workingPlans.table.approveWorkingPlan",
        defaultMessage: "Approve working plan",
        description: "Approve working plan",
    },
    rejectWorkingPlan: {
        id: "workingPlans.table.rejectWorkingPlan",
        defaultMessage: "Reject working plan",
        description: "Reject working plan",
    },
    sendWorkingPlan: {
        id: "workingPlans.table.sendWorkingPlan",
        defaultMessage: "Send plan",
        description: "Send plan",
    },
    approveSelected: {
        id: "general.actions.approveSelected",
        defaultMessage: "Approve selected",
        description: "Approve selected",
    },
    rejectSelected: {
        id: "general.actions.rejectSelected",
        defaultMessage: "Reject selected",
        description: "Reject selected",
    },
    deleteSelected: {
        id: "general.actions.deleteSelected",
        defaultMessage: "Delete selected",
        description: "Delete selected",
    },
    workingPlansSuccessfullySaved: {
        id: "workingPlans.validation.workingPlansSuccessfullySaved",
        defaultMessage: "Working plan successfully saved",
        description: "Working plan successfully saved",
    },
    workingPlansValidateSave: {
        id: "workingPlans.validation.workingPlansValidateSave",
        defaultMessage: "Error while saving working plan",
        description: "Error while saving working plan",
    },
    workingPlanCancelled: {
        id: "workingPlans.validation.workingPlanCancelled",
        defaultMessage: "Working plan cancelled",
        description: "Working plan cancelled",
    },
    confirmWorkingPlanCancel: {
        id: "workingPlans.validation.confirmWorkingPlanCancel",
        defaultMessage: "Are you sure you want to cancel creating your working plan?",
        description: "Are you sure you want to cancel creating your working plan?",
    },
    workingPlansDetails: {
        id: "workingPlans.table.workingPlansDetails",
        defaultMessage: "Working plan details",
        description: "Working plan details",
    },
    studentPlanDetails: {
        id: "workingPlans.table.studentPlanDetails",
        defaultMessage: "Student plan details",
        description: "Student plan details",
    },
    externalPlanDetails: {
        id: "workingPlans.table.externalPlanDetails",
        defaultMessage: "External plan details",
        description: "External plan details",
    },
    planDetails: {
        id: "workingPlans.table.planDetails",
        defaultMessage: "Plan details",
        description: "Plan details",
    },
    date: {
        id: "general.date",
        defaultMessage: "Date",
        description: "Date",
    },
    dates: {
        id: "general.dates",
        defaultMessage: "Dates",
        description: "Dates",
    },
    from: {
        id: "general.from",
        defaultMessage: "From",
        description: "From",
    },
    to: {
        id: "general.to",
        defaultMessage: "To",
        description: "To",
    },
    dateCreated: {
        id: "general.dateCreated",
        defaultMessage: "Date created",
        description: "Date created",
    },
    planDates: {
        id: "general.planDates",
        defaultMessage: "Plan dates",
        description: "Plan dates",
    },
    paymentReceiver: {
        id: "general.paymentReceiver",
        defaultMessage: "Payment receiver/Description",
        description: "Payment receiver/Description",
    },
    paymentType: {
        id: "general.paymentType",
        defaultMessage: "Payment type",
        description: "Payment type",
    },
    paymentDue: {
        id: "general.paymentDue",
        defaultMessage: "Payment due",
        description: "Payment due",
    },
    workDay: {
        id: "general.workDay",
        defaultMessage: "Work day",
        description: "Plan dates",
    },
    start: {
        id: "general.start",
        defaultMessage: "Start",
        description: "Start",
    },
    end: {
        id: "general.end",
        defaultMessage: "End",
        description: "End",
    },
    workStatus: {
        id: "general.workStatus",
        defaultMessage: "Work status",
        description: "Work status",
    },
    save: {
        id: "general.actions.save",
        defaultMessage: "Save",
        description: "Save",
    },
    workingPlans: {
        id: "workingPlans.table.workingPlans",
        defaultMessage: "Working plans",
        description: "Working plans",
    },
    student: {
        id: "general.student",
        defaultMessage: "Student",
        description: "Student",
    },
    external: {
        id: "general.external",
        defaultMessage: "External",
        description: "External",
    },
    previousDatesValidateFetch: {
        id: "workingPlans.validation.previousDatesValidateFetch",
        defaultMessage: "Unable to fetch previous dates",
        description: "Unable to fetch previous dates",
    },
    workingPlanCreateError: {
        id: "workingPlans.validation.workingPlanCreateError",
        defaultMessage: "Error while creating working plan",
        description: "Error while creating working plan",
    },
    creatingWorkingPlan: {
        id: "workingPlans.form.creatingWorkingPlan",
        defaultMessage: "Creating working plan:",
        description: "Creating working plan:",
    },
    mom: {
        id: "Minutes of meetings",
        defaultMessage: "Minutes of meetings",
        description: "Minutes of meetings",
    },
    employeeUtilization: {
        id: "Employee utilization",
        defaultMessage: "Employee utilization",
        description: "Employee utilization",
    },
    capacityPlanning: {
        id: "Capacity planning",
        defaultMessage: "Capacity planning",
        description: "Capacity planning",
    },
    projectsAllocation: {
        id: "Projects allocation",
        defaultMessage: "Projects allocation",
        description: "Projects allocation",
    },
    generateGnattChart: {
        id: "Generate Gantt chart",
        defaultMessage: "Generate Gantt chart",
        description: "Generate Gantt chart",
    },
    amount: {
        id: "Amount",
        defaultMessage: "Amount",
        description: "Amount",
    },
    bankStatements: {
        id: "Bank statements",
        defaultMessage: "Bank statements",
        description: "Bank statements",
    },
    bankStatementSuccessfullyDeleted: {
        id: "bankStatement.validation.successfullyDeleted",
        defaultMessage: "Bank statement successfully deleted",
        description: "Bank statement successfully deleted",
    },
    bankStatementsSuccessfullyDeleted: {
        id: "bankStatements.validation.successfullyDeleted",
        defaultMessage: "Bank statements successfully deleted",
        description: "Bank statements successfully deleted",
    },
    invoiceSuccessfullyDeleted: {
        id: "invoice.validation.successfullyDeleted",
        defaultMessage: "Invoice successfully deleted",
        description: "Invoice successfully deleted",
    },
    invoicesSuccessfullyDeleted: {
        id: "invoices.validation.successfullyDeleted",
        defaultMessage: "Invoices successfully deleted",
        description: "Invoices successfully deleted",
    },
    invoices: {
        id: "Invoices",
        defaultMessage: "Invoices",
        description: "Invoices",
    },
    invoicesName: {
        id: "Invoices name",
        defaultMessage: "Invoices",
        description: "Invoices",
    },
    duplicateEmail: {
        id: "user.duplicateEmail",
        defaultMessage: "User with selected email address already exist",
        description: "User with selected email address already exist",
    },
    invoiceCategories: {
        id: "Invoice categories",
        defaultMessage: "Invoice categories",
        description: "Invoice categories",
    },
    createNewInvoice: {
        id: "Create new invoice",
        defaultMessage: "Create new invoice",
        description: "Create new invoice",
    },
    clientsAndVendors: {
        id: "Clients and vendors",
        defaultMessage: "Clients and vendors",
        description: "Clients and vendors",
    },
    clientContacts: {
        id: "Client contacts",
        defaultMessage: "Client contacts",
        description: "Client contacts",
    },
    vendorContacts: {
        id: "Vendor contacts",
        defaultMessage: "Vendor contacts",
        description: "Vendor contacts",
    },
    administration: {
        id: "Administration",
        defaultMessage: "Administration",
        description: "Administration",
    },
    isEmployed: {
        id: "users.employed",
        defaultMessage: "Is employed",
        description: "Is employed",
    },
    isEnabled: {
        id: "users.enabled",
        defaultMessage: "Is enabled",
        description: "Is enabled",
    },
    employeeData: {
        id: "Employee Data",
        defaultMessage: "Employee Data",
        description: "Employee Data",
    },
    myData: { id: "My Data", defaultMessage: "My Data", description: "My Data" },
    invoiceProcessing: {
        id: "Invoice Processing",
        defaultMessage: "Invoice Processing",
        description: "Invoice Processing",
    },
    requests: { id: "Requests", defaultMessage: "Requests", description: "Requests" },
    contacts: { id: "Contacts", defaultMessage: "Contacts", description: "Contacts" },
    accessRequests: {
        id: "Access requests",
        defaultMessage: "Access requests",
        description: "Access requests",
    },
    adminOnly: { id: "Admin only", defaultMessage: "Admin only", description: "Admin only" },
    logout: { id: "Logout", defaultMessage: "Logout", description: "Logout" },
    english: { id: "english", defaultMessage: "English", description: "english language" },
    croatian: { id: "croatian", defaultMessage: "Croatian", description: "croatian language" },
    partners: { id: "partners", defaultMessage: "Partners", description: "Partners" },
    documents: { id: "Documents", defaultMessage: "Documents", description: "Documents" },
    selectAll: {
        id: "Select all",
        defaultMessage: "Select all",
        description: "Aconto table bulk select button",
    },
    deselectAll: {
        id: "Deselect all",
        defaultMessage: "Deselect all",
        description: "Aconto table bulk select button",
    },
    close: { id: "Close", defaultMessage: "Close" },
    documentCategoryRequirement: {
        id: "general.validation.requiredDocumentCategory",
        defaultMessage: "Document category is required",
        description: "Document category is required",
    },
    documentValidUntilRequirement: {
        id: "general.validation.requiredDocumentValidUnitl",
        defaultMessage: "Valin until date is required",
        description: "Valin until date is required",
    },
    noNewNotifications: { id: "noNewNotifications", defaultMessage: "No new notifications" },
    vacationRequestsPending: {
        id: "vacationRequestsPending",
        defaultMessage: "Vacation requests pending",
    },
    yearlyVacationRequestsPending: {
        id: "yearlyVacationRequestsPending",
        defaultMessage: "Yearly vacation plan requests pending",
    },
    sickLeaveRequestPending: {
        id: "sickLeaveRequestPending",
        defaultMessage: "Sick leave requests pending",
    },
    paidLeaveRequestPending: {
        id: "paidLeaveRequestPending",
        defaultMessage: "Paid leave requests pending",
    },
    unpaidLeaveRequestPending: {
        id: "unpaidLeaveRequestPending",
        defaultMessage: "Unpaid leave requests pending",
    },
    parentalLeaveRequestPending: {
        id: "parentalLeaveRequestPending",
        defaultMessage: "Parental leave requests pending",
    },
    remoteRequestPending: {
        id: "remoteRequestPending",
        defaultMessage: "Remote requests pending",
    },
    buyRequestNew: {
        id: "buyRequestNew",
        defaultMessage: "added a new buy request",
    },
    buyRequestPending: {
        id: "buyRequestPending",
        defaultMessage: "Buy requests pending",
    },
    WorkingPlanPendingStudent: {
        id: "studentWorkingPlanPending",
        defaultMessage: "Student working plans pending",
    },
    WorkingPlanPendingExternal: {
        id: "externalWorkingPlanPending",
        defaultMessage: "External working plans pending",
    },
    vacationRequestApproved: {
        id: "vacationRequestApproved",
        defaultMessage: "Your vacation request has been approved",
    },
    vacationRequestDeletionApproved: {
        id: "vacationRequestDeletionApproved",
        defaultMessage: "Your vacation request has been deleted",
    },
    vacationRequestAlreadyApproved: {
        id: "vacationRequestAlreadyApproved",
        defaultMessage: "This vacationRequest has been approved",
    },
    vacationRequestRejected: {
        id: "vacationRequestRejected",
        defaultMessage: "Your vacation request has been rejected",
    },
    vacationRequestAlreadyRejected: {
        id: "vacationRequestAlreadyRejected",
        defaultMessage: "This vacationRequest has been rejected",
    },
    vacationRequestNew: {
        id: "vacationRequestNew",
        defaultMessage: "has added a planned vacation",
    },
    vacationRequestDeleted: {
        id: "VacationRequestDeleted",
        defaultMessage: "has deleted their planned vacation",
    },
    sickLeaveRequestNew: {
        id: "sickLeaveRequestNew",
        defaultMessage: "has added a planned sick leave",
    },
    sickLeaveDeleted: {
        id: "SickLeaveDeleted",
        defaultMessage: "has deleted their planned sick leave",
    },
    yearlyVacationPlanNew: {
        id: "yearlyVacationPlanNew",
        defaultMessage: "has added a new yearly vacation plan",
    },
    yearlyVacationPlanDeleted: {
        id: "YearlyVacationPlanDeleted",
        defaultMessage: "has deleted their yearly vacation plan",
    },
    paidLeaveRequestNew: {
        id: "paidLeaveRequestNew",
        defaultMessage: "has added a new planned paid leave",
    },
    paidLeaveDeleted: {
        id: "PaidLeaveDeleted",
        defaultMessage: "has deleted their planned paid leave",
    },
    unpaidLeaveRequestNew: {
        id: "unpaidLeaveRequestNew",
        defaultMessage: "has added a new planned unpaid leave",
    },
    unpaidLeaveDeleted: {
        id: "UnpaidLeaveDeleted",
        defaultMessage: "has deleted their planned unpaid leave",
    },
    parentalLeaveRequestNew: {
        id: "parentalLeaveRequestNew",
        defaultMessage: "has added a new planned parental leave",
    },
    parentalLeaveDeleted: {
        id: "ParentalLeaveDeleted",
        defaultMessage: "has deleted their planned parental leave",
    },
    remoteRequestNew: {
        id: "remoteRequestNew",
        defaultMessage: "has added a new planned remote work",
    },
    remoteWorkDeleted: {
        id: "RemoteWorkDeleted",
        defaultMessage: "has deleted their planned remote work",
    },
    yearlyVacationPlanApproved: {
        id: "yearlyVacationPlanApproved",
        defaultMessage: "Yearly vacation plan approved",
        description: "Yearly vacation plan approved",
    },
    yearlyVacationPlanDeletionApproved: {
        id: "yearlyVacationPlanDeletionApproved",
        defaultMessage: "Yearly vacation plan deleted",
        description: "Yearly vacation plan deleted",
    },
    yearlyVacationPlanRejected: {
        id: "yearlyVacationPlanRejected",
        defaultMessage: "Yearly vacation plan rejected",
        description: "Yearly vacation plan rejected",
    },
    yearlyVacationPlanGuidelineHeader: {
        id: "yearlyVacationPlanGuidelineHeader",
        defaultMessage: "To successfully enter your vacation plan, please follow these guidelines",
        description: "To successfully enter your vacation plan, please follow these guidelines",
    },
    yearlyVacationPlanGuidelineEnterAll: {
        id: "yearlyVacationPlanGuidelineEnterAll",
        defaultMessage:
            "You must enter all of your vacation days - if you get 25 vacation days per year, you need to enter 25 days.",
        description:
            "You must enter all of your vacation days - if you get 25 vacation days per year, you need to enter 25 days.",
    },
    yearlyVacationPlanGuidelineContinous: {
        id: "yearlyVacationPlanGuidelineContinous",
        defaultMessage:
            "Your plan must contain 10 continuous days of vacation, so two full weeks, for example.",
        description:
            "Your plan must contain 10 continuous days of vacation, so two full weeks, for example.",
    },
    yearlyVacationPlanGuidelineFooter: {
        id: "yearlyVacationPlanGuidelineFooter",
        defaultMessage:
            "Don't worry, this is just a plan and can change through the year, it is not set in stone.",
        description:
            "Don't worry, this is just a plan and can change through the year, it is not set in stone.",
    },
    sickLeaveRequestApproved: {
        id: "sickLeaveRequestApproved",
        defaultMessage: "Your sick leave request has been approved",
    },
    sickLeaveRequestDeletionApproved: {
        id: "sickLeaveRequestDeletionApproved",
        defaultMessage: "Your sick leave request has been deleted",
        description: "Your sick leave request has been deleted",
    },
    sickLeaveRequestRejected: {
        id: "sickLeaveRequestRejected",
        defaultMessage: "Your sick leave request has been rejected",
    },
    paidLeaveRequestApproved: {
        id: "paidLeaveRequestApproved",
        defaultMessage: "Your paid leave request has been approved",
    },
    paidLeaveRequestDeletionApproved: {
        id: "paidLeaveRequestDeletionApproved",
        defaultMessage: "Your paid leave request has been deleted",
    },
    paidLeaveRequestRejected: {
        id: "paidLeaveRequestRejected",
        defaultMessage: "Your paid leave request has been rejected",
    },
    unpaidLeaveRequestApproved: {
        id: "unpaidLeaveRequestApproved",
        defaultMessage: "Your unpaid leave request has been approved",
    },
    unpaidLeaveRequestDeletionApproved: {
        id: "unpaidLeaveRequestDeletionApproved",
        defaultMessage: "Your unpaid leave request has been deleted",
    },
    unpaidLeaveRequestRejected: {
        id: "unpaidLeaveRequestRejected",
        defaultMessage: "Your unpaid leave request has been rejected",
    },
    parentalLeaveRequestApproved: {
        id: "parentalLeaveRequestApproved",
        defaultMessage: "Your parental leave request has been approved",
    },
    parentalLeaveRequestDeletionApproved: {
        id: "parentalLeaveRequestDeletionApproved",
        defaultMessage: "Your parental leave request has been deleted",
    },
    parentalLeaveRequestRejected: {
        id: "parentalLeaveRequestRejected",
        defaultMessage: "Your parental leave request has been rejected",
    },
    remoteRequestApproved: {
        id: "remoteRequestApproved",
        defaultMessage: "Your remote request has been approved",
    },
    remoteRequestDeletionApproved: {
        id: "remoteRequestDeletionApproved",
        defaultMessage: "Your remote request has been deleted",
    },
    remoteRequestRejected: {
        id: "remoteRequestRejected",
        defaultMessage: "Your remote request has been rejected",
    },
    buyRequestApproved: {
        id: "buyRequestApproved",
        defaultMessage: "Your buy request has been approved",
    },
    workingPlanApproved: {
        id: "workingPlanApproved",
        defaultMessage: "Your working plan has been approved",
        description: "Your working plan has been approved",
    },
    workingPlanRejected: {
        id: "workingPlanRejected",
        defaultMessage: "Your working plan has been rejected",
        description: "Your working plan has been rejected",
    },
    workingPlanDeleted: {
        id: "workingPlanDeleted",
        defaultMessage: "Your working plan has been deleted",
        description: "Your working plan has been deleted",
    },
    buyRequestRejected: {
        id: "buyRequestRejected",
        defaultMessage: "Your buy request has been rejected",
    },
    buyRequestDeleted: {
        id: "buyRequestDeleted",
        defaultMessage: "Your buy request has been deleted",
    },
    viewReportsSummary: {
        id: "View Reports Summary",
        defaultMessage: "View Reports Summary",
        description: "View Reports Summary",
    },
    viewCollaboratorsReports: {
        id: "viewCollaboratorsReports",
        defaultMessage: "View Collaborators Reports",
        description: "View Collaborators Reports",
    },
    yourReportsFor: {
        id: "reports.yourReportsFor",
        defaultMessage: "Your reports for",
        description: "Your reports for",
    },
    reportsFor: {
        id: "reports.reportsFor",
        defaultMessage: "Reports for",
        description: "Reports for",
    },
    reportsSummaryFor: {
        id: "reports.reportsSummaryFor",
        defaultMessage: "Reports summary for",
        description: "Reports summary for",
    },
    collaboratorsReportsFor: {
        id: "reports.collaboratorsReportsFor",
        defaultMessage: "Collaborators reports for",
        description: "Collaborators reports for",
    },
    addReports: {
        id: "reports.AddReports",
        defaultMessage: "Add Reports",
        description: "Add Reports",
    },
    selectReportsToExport: {
        id: "reports.selectReportsToExport",
        defaultMessage: "Select reports to export",
        description: "Select reports to export",
    },
    selectProjects: {
        id: "reports.selectProjects",
        defaultMessage: "Select projects",
        description: "Select projects",
    },
    selectDataRange: {
        id: "reports.selectDataRange",
        defaultMessage: "Select date range",
        description: "Select date range",
    },
    markAsRead: { id: "markAsRead", defaultMessage: "Mark all as read" },
    partnersAllPartners: {
        id: "partners.allPartners",
        defaultMessage: "All partners",
        description: "All partners",
    },
    partnersCompanyName: {
        id: "partners.companyName",
        defaultMessage: "Company name",
        description: "Company name",
    },
    partnerType: {
        id: "partners.partnerType",
        defaultMessage: "Partner type",
        description: "Partner type",
    },
    partnersEditPartner: {
        id: "partners.editPartner",
        defaultMessage: "Edit partner",
        description: "Edit partner",
    },
    partnersAddNewPartner: {
        id: "partners.addNewPartner",
        defaultMessage: "Add new partner",
        description: "Add new partner",
    },
    partnerDetails: {
        id: "partner.details",
        defaultMessage: "Partner details",
        description: "Partner details",
    },
    partnerDeletePartner: {
        id: "partners.deletePartner",
        defaultMessage: "Are you sure you want to delete this partner?",
        description: "Partner delete",
    },
    partnerCompanyName: {
        id: "partners.companyName2",
        defaultMessage: "Partner company name",
        description: "Partner company name",
    },
    unableToFetchPartners: {
        id: "unableToFetchPartners",
        defaultMessage: "Unable to fetch partners",
        description: "Unable to fetch partners",
    },
    partnerContacts: {
        id: "partner.contacts",
        defaultMessage: "Partner contacts",
        description: "Partner contacts",
    },
    noContacts: {
        id: "partners.noContacts",
        defaultMessage: "No contacts",
        description: "Partner no contacts",
    },
    partnerNotFound: {
        id: "partners.notFound",
        defaultMessage: "Partner not found",
        description: "Partner not found",
    },
    partnerDeleteContact: {
        id: "partners.deleteContact",
        defaultMessage: "Are you sure you want to delete this contact?",
        description: "Contact delete",
    },
    partnersEditContact: {
        id: "partners.editContact",
        defaultMessage: "Edit contact",
        description: "Edit contact",
    },
    partnersAddNewContact: {
        id: "partners.addNewContact",
        defaultMessage: "Add new contact",
        description: "Add new contact",
    },
    unableToFetchPartnerContacts: {
        id: "unableToFetchPartnerContacts",
        defaultMessage: "Unable to fetch partner contacts",
        description: "Unable to fetch partner contacts",
    },
    type: {
        id: "reports.type",
        defaultMessage: "Type",
        description: "Report type",
    },
    jobTitles: {
        id: "amidnistrativeSettings.jobClasification.jobTitles",
        defaultMessage: "Job titles",
        description: "Job titles",
    },
    noJobTitles: {
        id: "amidnistrativeSettings.jobClasification.noJobTitles",
        defaultMessage: "No job titles",
        description: "No job titles",
    },
    editJobTitle: {
        id: "amidnistrativeSettings.jobClasification.editJobTitle",
        defaultMessage: "Edit job title",
        description: "Edit job title",
    },
    addJobTitle: {
        id: "amidnistrativeSettings.jobClasification.addJobTitle",
        defaultMessage: "Add job title",
        description: "Add job title",
    },
    jobTitleErrorFethcing: {
        id: "amidnistrativeSettings.jobClasification.jobTitleErrorFethcing",
        defaultMessage: "Error while fetching job titles",
        description: "Error while fetching job titles",
    },
    jobTitleSuccessfullyCreated: {
        id: "amidnistrativeSettings.jobClasification.jobTitleSuccessfullyCreated",
        defaultMessage: "Job title successfully created",
        description: "Job title successfully created",
    },
    jobTitleSuccessfullyEdit: {
        id: "amidnistrativeSettings.jobClasification.jobTitleSuccessfullyEdit",
        defaultMessage: "Job title successfully edited",
        description: "Job title successfully edited",
    },
    jobTitleSuccessfullyDeleted: {
        id: "amidnistrativeSettings.jobClasification.jobTitleSuccessfullyDeleted",
        defaultMessage: "Job title successfully deleted",
        description: "Job title successfully deleted",
    },
    jobTitleErrorWhileDeliting: {
        id: "amidnistrativeSettings.jobClasification.jobTitleErrorWhileDeliting",
        defaultMessage: "Unable to delete Job title",
        description: "Unable to delete Job title",
    },
    jobTitleErrorWhileAdding: {
        id: "amidnistrativeSettings.jobClasification.jobTitleErrorWhileAdding",
        defaultMessage: "Unable to add new Job title",
        description: "Unable to add new Job title",
    },
    jobTitleValidationName: {
        id: "amidnistrativeSettings.jobClasification.validation.jobTitleValidationName",
        defaultMessage: "Job title is required",
        description: "Job title is required. ",
    },
    addPurchaseAssignee: {
        id: "add.PurchaseAssignee",
        defaultMessage: "Add purchase assignee",
        description: "Add purchase assignee",
    },
    validationPurchaseAssignee: {
        id: "validation.PurchaseAssignee",
        defaultMessage: "You must declare purchase assignee",
        description: "You must declare purchase assignee",
    },
    bankStatementMessageSuccessfullyAddedToinvoice: {
        id: "bankStatement.message.successfullyAddedToInvoice",
        defaultMessage: "Bank statements successfully added to invoice",
        description: "Bank statements successfully added to invoice",
    },
    bankStatementMessageaAddToInvoiceFailed: {
        id: "bankStatement.message.addToInvoiceFailed",
        defaultMessage: "Error while trying to add bank statements to invoice ",
        description: "Error while trying to add bank statements to invoice",
    },
    invoiceMessageSuccessfullyAddedToBankStatement: {
        id: "invoice.message.successfullyAddedToBankStatement",
        defaultMessage: "Invoices successfully added to bank statement",
        description: "Invoices successfully added to bank statement",
    },
    invoiceMessageaAddToBankStatementFailed: {
        id: "invoice.message.addToBankStatementFailed",
        defaultMessage: "Error while trying to add invoices to bank statement",
        description: "Error while trying to add invoices to bank statement",
    },
    addInvoices: {
        id: "invoice.add",
        defaultMessage: "Add invoices",
        description: "Add invoices",
    },
    addBankStatements: {
        id: "bankStatement.add",
        defaultMessage: "Add bank statements",
        description: "Add bank statements",
    },
    userDetailsMessagePasswordChangedSuccessfully: {
        id: "userDetails.messagePasswordChangedSuccessfully",
        defaultMessage: "Password changed successfully",
        description: "Password changed successfully",
    },
    userDetailsMessagePasswordChangedFailed: {
        id: "userDetails.messagePasswordChangedFailed",
        defaultMessage: "Error while changing password",
        description: "Error while changing password",
    },
    userDetailsMessagePasswordChangedNotPossible: {
        id: "userDetails.messagePasswordChangedNotPossible",
        defaultMessage: "Unable to change password",
        description: "Unable to change password",
    },
    vehicleDetails: {
        id: "vehicles.details",
        defaultMessage: "Vehicle details",
        description: "Vehicle details",
    },
    editVehicle: {
        id: "vehicles.edit",
        defaultMessage: "Edit vehicle",
        description: "Edit vehicle",
    },
    vehicleTable: {
        id: "vehicles.table.id",
        defaultMessage: "Vehicles",
        description: "Vehicles table",
    },
    vehicleButtonNew: {
        id: "vehicles.button.new",
        defaultMessage: "Add new vehicle",
        description: "Add new vehicle",
    },
    vehicleTitleVehicles: {
        id: "vehicles.title.vehicles",
        defaultMessage: "Vehicles",
        description: "Vehicles",
    },
    vehicleTitleVehicle: {
        id: "vehicles.title.vehicle",
        defaultMessage: "Vehicle",
        description: "Vehicle",
    },
    vehicleTitleNewVehicle: {
        id: "vehicles.title.new.vehicle",
        defaultMessage: "New Vehicle",
        description: "New Vehicle",
    },
    vehiclesErrorFetching: {
        id: "vehicles.error.fetching",
        defaultMessage: "Error while fetching vehicles",
        description: "Error while fetching vehicles",
    },
    vehiclesErrorFetchDetail: {
        id: "vehicles.error.fetch.detail",
        defaultMessage: "Unable to fetch Vehicle details",
        description: "Unable to fetch Vehicle details",
    },
    vehicleFieldModel: {
        id: "vehicles.field.model",
        defaultMessage: "Model",
        description: "Model",
    },
    vehicleFieldVin: {
        id: "vehicles.field.vin",
        defaultMessage: "Vin",
        description: "Vin",
    },
    vehicleFieldRegistrationPlate: {
        id: "vehicles.field.registration.plate",
        defaultMessage: "Registration Plate",
        description: "Registration Plate",
    },
    vehicleFieldManufacturer: {
        id: "vehicles.field.manufacturer",
        defaultMessage: "Manufacturer",
        description: "Manufacturer",
    },
    vehicleFieldVIN: {
        id: "vehicles.field.vin",
        defaultMessage: "VIN",
        description: "VIN",
    },
    vehicleFieldColor: {
        id: "vehicles.field.color",
        defaultMessage: "Color",
        description: "Color",
    },
    vehicleFieldActive: {
        id: "vehicles.field.active",
        defaultMessage: "Active",
        description: "Active",
    },
    vehicleFieldCompanyProperty: {
        id: "vehicles.field.companyProperty",
        defaultMessage: "Company property",
        description: "Company property",
    },
    vehicleMessageCreated: {
        id: "vehicles.message.created",
        defaultMessage: "Vehicle created",
        description: "Vehicle created",
    },
    vehicleMessageCreatedContinueEditing: {
        id: "vehicles.message.created.continue.editing",
        defaultMessage:
            "Vehicle successfully created. Do you want to continue editing vehicle details?",
        description:
            "Vehicle successfully created. Do you want to continue editing vehicle details?",
    },
    vehicleSuccessfullyDeleted: {
        id: "vehicles.validation.successfullyDeleted",
        defaultMessage: "Vehicle successfully deleted",
        description: "Vehicle successfully deleted",
    },
    vehicleValidateDelete: {
        id: "vehicles.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete vehicle",
        description: "Unable to delete vehicle",
    },
    commonMessageShortSecond: {
        id: "common.message.short.second",
        defaultMessage: "s",
        description: "Second",
    },
    commonMessageAutomaticallyRedirected: {
        id: "common.message.automatically.redirected",
        defaultMessage: "You will be automatically redirected to the home page in",
        description: "Second",
    },
    vehiclesErrorRegistrationPlate: {
        id: "vehicles.error.registration.plate",
        defaultMessage: "Vehicle's registration plate is required.",
        description: "Vehicle's registration plate is required.",
    },
    menuVehicles: {
        id: "menu.vehicles",
        defaultMessage: "Vehicle's registration plate is required.",
        description: "Vehicle's registration plate is required.",
    },
    mergeBankStatements: {
        id: "bankStatements.merge",
        defaultMessage: "Merge bank statements",
        description: "Merge bank statement",
    },
    mergeSalaryExpenses: {
        id: "bankStatements.mergeSalaryExpenses",
        defaultMessage: "Merge salary expenses",
        description: "Merge salary expenses",
    },
    mergeSalaryExpensesSuccessfull: {
        id: "bankStatements.validation.mergeSalaryExpensesSuccessfull",
        defaultMessage: "Salary expenses successfully merged by month",
        description: "Salary expenses successfully merged by month",
    },
    dailyReportReminder: {
        id: "dailyReportReminder",
        defaultMessage: "If you haven't already, please enter your daily reports to Aconto.",
        description: "If you haven't already, please enter your daily reports to Aconto.",
    },
    monthlyWorkAttendancesReminder: {
        id: "monthlyWorkAttendancesReminder",
        defaultMessage:
            "If you haven't already, please enter your vacations, sick leaves and paid leaves from previous month to Aconto.",
        description:
            "If you haven't already, please enter your vacations, sick leaves and paid leaves from previous month to Aconto.",
    },
    studentContractsReminder: {
        id: "studentContractsReminder",
        defaultMessage: "Don't forget to pick up a new student contract for the next month",
        description: "Don't forget to pick up a new student contract for the next month",
    },
    studentPlansReminder: {
        id: "studentPlansReminder",
        defaultMessage: "Please fill out your student plan for next week.",
        description: "Please fill out your student plan for next week.",
    },
    ibansValidateFetch: {
        id: "ibans.messageIbanFetchingError",
        defaultMessage: "Error while fetching ibans",
        description: "Error while fetching ibans",
    },
    unprocessedValidateFetch: {
        id: "bankStatements.messageUnprocessedFetchingError",
        defaultMessage: "Error while fetching number of unprocessed bank statements and invoices",
        description: "Error while fetching number of unprocessed bank statements and invoices",
    },
    yearlyVacationPlanYearsValidateFetch: {
        id: "workAttendances.messageYearlyVacationPlanYearsFetchingError",
        defaultMessage: "Error while fetching years available for yearly vacation plan creation",
        description: "Error while fetching years available for yearly vacation plan creation",
    },
    filterByBanks: {
        id: "ibans.filterByBanks",
        defaultMessage: "Filter by banks",
        description: "Filter by banks",
    },
    filterByUser: {
        id: "locoRides.filterByUser",
        defaultMessage: "Filter by user",
        description: "Filter by user",
    },
    filterByMonth: {
        id: "locoRides.filterByMonth",
        defaultMessage: "Filter by month",
        description: "Filter by month",
    },
    iban: {
        id: "ibans.iban",
        defaultMessage: "IBAN",
        description: "IBAN",
    },
    newestChange: {
        id: "ibans.newestChange",
        defaultMessage: "Newest Change",
        description: "Newest Change",
    },
    oldBalance: {
        id: "ibans.oldBalance",
        defaultMessage: "Old Balance",
        description: "Old Balance",
    },
    newBalance: {
        id: "ibans.newBalance",
        defaultMessage: "New Balance",
        description: "New Balance",
    },
    temporaryBalance: {
        id: "ibans.temporaryBalance",
        defaultMessage: "Temporary Balance",
        description: "Temporary Balance",
    },
    reservedForPayment: {
        id: "ibans.reservedForPayment",
        defaultMessage: "Reserved For Payment",
        description: "Reserved For Payment",
    },
    allowedOverdraft: {
        id: "ibans.allowedOverdraft",
        defaultMessage: "Allowed Overdraft",
        description: "Allowed Overdraft",
    },
    reservedByOrderOfFina: {
        id: "ibans.reservedByOrderOfFina",
        defaultMessage: "Reserved By Order Of Fina",
        description: "Reserved By Order Of Fina",
    },
    availableBalance: {
        id: "ibans.availableBalance",
        defaultMessage: "Available Balance",
        description: "Available Balance",
    },
    administrativeSettings: {
        id: "administrativeSettings",
        defaultMessage: "Administrative settings",
        description: "Administrative settings",
    },
    companyDetails: {
        id: "companyDetails",
        defaultMessage: "Company details",
        description: "Company details",
    },
    financeSettings: {
        id: "financeSettings",
        defaultMessage: "Finance Settings",
        description: "Finance Settings",
    },
    unprocessed: {
        id: "unprocessed",
        defaultMessage: "unprocessed",
        description: "unprocessed",
    },
    onHold: {
        id: "onHold",
        defaultMessage: "on hold",
        description: "on hold",
    },
    sessionExpired: {
        id: "sessionExpired",
        defaultMessage: "Your session has expired",
    },
    sessionExpiredMessage: {
        id: "sessionExpiredMessage",
        defaultMessage: "Your session has expired, please log in again",
    },
    itemCreatedSuccessTemplate: {
        id: "itemCreatedSuccessTemplate",
        defaultMessage: "Successfully created new _ITEM_NAME_",
    },
    itemEditedSuccessTemplate: {
        id: "itemEditedSuccessTemplate",
        defaultMessage: "Successfully edited _ITEM_NAME_",
    },
    itemCreatedErrorTemplate: {
        id: "itemCreatedErrorTemplate",
        defaultMessage: "Unable to add new _ITEM_NAME_",
    },
    itemEditedErrorTemplate: {
        id: "itemEditedErrorTemplate",
        defaultMessage: "Unable to edit _ITEM_NAME_",
    },
    itemAddHeaderTemplate: {
        id: "itemAddHeaderTemplate",
        defaultMessage: "Add new _ITEM_NAME_",
    },
    itemEditHeaderTemplate: {
        id: "itemEditHeaderTemplate",
        defaultMessage: "Edit _ITEM_NAME_",
    },
    itemDeleteHeaderTemplate: {
        id: "itemDeleteHeaderTemplate",
        defaultMessage: "Delete _ITEM_NAME_",
    },
    itemDeleteMessageTemplate: {
        id: "itemDeleteMessageTemplate",
        defaultMessage: "Are you sure you want to delete _ITEM_NAME_?",
    },
    itemNameValidationTemplate: {
        id: "itemNameValidationTemplate",
        defaultMessage: "Name of _ITEM_NAME_ is required.",
    },
    itemDeletedSuccessTemplate: {
        id: "itemDeletedSuccessTemplate",
        defaultMessage: "_ITEM_NAME_ successfully deleted",
    },
    itemDeletedErrorTemplate: {
        id: "itemDeletedErrorTemplate",
        defaultMessage: "Unable to delete _ITEM_NAME_",
    },
    finance: {
        id: "Finance",
        defaultMessage: "Finance",
    },
    projectRoles: {
        id: "reportsProjectRoles",
        defaultMessage: "Project roles",
    },
    jobClassification: {
        id: "job.classification",
        defaultMessage: "Job classification",
        description: "Job classification",
    },
    reportCategories: {
        id: "report.categories",
        defaultMessage: "Report categories",
        description: "Report categories",
    },
    reportCategory: {
        id: "reportCategory",
        defaultMessage: "Report category",
        description: "Report category",
    },
    editReportCategory: {
        id: "report.category.edit",
        defaultMessage: "Edit report category",
        description: "Edit report category",
    },
    addNewReportCategory: {
        id: "report.category.add",
        defaultMessage: "Add new report category",
        description: "Add new report category",
    },
    noCategories: {
        id: "report.categories.noCategories",
        defaultMessage: "No categories",
        description: "No categories",
    },
    appSize: {
        id: "appSize",
        defaultMessage: "Display size",
    },
    invoiceCategorySuccessfullyDeleted: {
        id: "invoiceCategory.message.successfullyDeleted",
        defaultMessage: "Invoice category successfully deleted",
        description: "Invoice category successfully deleted",
    },
    invoiceCategoryDeleteError: {
        id: "invoiceCategory.message.deleteError",
        defaultMessage: "Error while deleting invoice category",
        description: "Error while deleting invoice category",
    },
    invoiceCategoryFetchError: {
        id: "invoiceCategory.message.fetchingEroror",
        defaultMessage: "Error while fetching invoice categories",
        description: "Error while fetching invoice categories",
    },
    projectRoleSuccessfullyDeleted: {
        id: "projectRole.message.successfullyDeleted",
        defaultMessage: "Project role successfully deleted",
        description: "Project role successfully deleted",
    },
    projectRoleDeleteError: {
        id: "projectRole.message.deleteError",
        defaultMessage: "Error while deleting project role",
        description: "Error while deleting project role",
    },
    projectRoleFetchError: {
        id: "projectRole.message.fetchingEroror",
        defaultMessage: "Error while fetching project roles",
        description: "Error while fetching project roles",
    },
    documentCategoryFetchError: {
        id: "documentCategory.message.fetchingEroror",
        defaultMessage: "Error while fetching document categories",
        description: "Error while fetching document Categories",
    },
    documentCategorySuccessfullyDeleted: {
        id: "documentCategory.message.successfullyDeleted",
        defaultMessage: "Document category successfully deleted",
        description: "Document category successfully deleted",
    },
    documentCategoryDeleteError: {
        id: "documentCategory.message.deleteError",
        defaultMessage: "Error while deleting document category",
        description: "Error while deleting document category",
    },
    serviceCategorySuccessfullyDeleted: {
        id: "serviceCategory.message.successfullyDeleted",
        defaultMessage: "Service category successfully deleted",
        description: "Service category successfully deleted",
    },
    serviceCategoryDeleteError: {
        id: "serviceCategory.message.deleteError",
        defaultMessage: "Error while deleting service category",
        description: "Error while deleting service category",
    },
    serviceCategoryFetchError: {
        id: "serviceCategory.message.fetchingEroror",
        defaultMessage: "Error while fetching service categories",
        description: "Error while fetching service categories",
    },
    serviceNotFound: {
        id: "service.message.notFound",
        defaultMessage: "Service not found",
        description: "Service not found",
    },
    documentClassificationSuccessfullyCreated: {
        id: "documentClassification.message.successfullyCreated",
        defaultMessage: "Document classification successfully created",
        description: "Document classification successfully created",
    },
    documentClassificationCreateError: {
        id: "documentClassification.message.createError",
        defaultMessage: "Error while creating document classification",
        description: "Error while creating document classification",
    },
    documentClassificationSuccessfullyEdited: {
        id: "documentClassification.message.successfullyEdited",
        defaultMessage: "Document classification successfully updated",
        description: "Document classification successfully updated",
    },
    documentClassificationEditError: {
        id: "documentClassification.message.editError",
        defaultMessage: "Error while updating document classification",
        description: "Error while updating document classification",
    },
    documentClassificationSuccessfullyDeleted: {
        id: "documentClassification.message.successfullyDeleted",
        defaultMessage: "Document classification successfully deleted",
        description: "Document classification successfully deleted",
    },
    documentClassificationDeleteError: {
        id: "documentClassification.message.deleteError",
        defaultMessage: "Error while deleting document classification",
        description: "Error while deleting document classification",
    },
    documentClassificationFetchError: {
        id: "documentClassification.message.fetchingEroror",
        defaultMessage: "Error while fetching document classification",
        description: "Error while fetching document classification",
    },
    myProfile: {
        id: "myProfile",
        defaultMessage: "My profile",
        description: "My profile",
    },
    MyProfile: {
        id: "My profile",
        defaultMessage: "My profile",
        description: "My profile",
    },
    PartnerContacts: {
        id: "Partner contacts",
        defaultMessage: "Partner contacts",
        description: "Partner contacts",
    },
    searchPlaceholder: {
        id: "searchPlaceholder",
        defaultMessage: "Search...",
        description: "Search...",
    },
    searchServices: {
        id: "searchServices",
        defaultMessage: "Search services...",
        description: "Search services...",
    },
    serialNumber: {
        id: "serialNumber",
        defaultMessage: "Serial number",
    },
    internalNumber: {
        id: "internalNumber",
        defaultMessage: "Internal number",
    },
    dateOfPurchase: {
        id: "dateOfPurchase",
        defaultMessage: "Date Of Purchase",
    },
    value: {
        id: "value",
        defaultMessage: "Value",
    },
    inventoryItems: {
        id: "Inventory items",
        defaultMessage: "Inventory items",
    },
    editInventoryItem: {
        id: "inventoryItem.edit",
        defaultMessage: "Edit inventory items",
    },
    inventoryItemDetails: {
        id: "inventoryItem.details",
        defaultMessage: "Inventory item details",
        description: "Inventory item details",
    },
    inventoryItemValidateEdit: {
        id: "inventoryItem.validation.unsuccessfulEdit",
        defaultMessage: "Unable to edit inventory item details",
        description: "Unable to edit inventory item details",
    },
    inventoryItemFetchError: {
        id: "inventoryItem.message.fetchingEroror",
        defaultMessage: "Error while fetching inventory item",
        description: "Error while fetching inventory item",
    },
    inventoryItemsFetchError: {
        id: "inventoryItems.message.fetchingEroror",
        defaultMessage: "Error while fetching inventory items",
        description: "Error while fetching inventory items",
    },
    inventoryItemsExportError: {
        id: "inventoryItems.message.exportError",
        defaultMessage: "Error while exporting inventory",
        description: "Error while exporting inventory",
    },
    inventoryItemSuccessfullyDeleted: {
        id: "inventoryItem.validation.successfullyDeleted",
        defaultMessage: "Inventory item successfully deleted",
        description: "Inventory item successfully deleted",
    },
    inventoryItemValidateDelete: {
        id: "inventoryItem.validation.unsuccessfulDelete",
        defaultMessage: "Unable to delete inventory item",
        description: "Unable to delete inventory item",
    },
    inventoryItemSuccessfullyCreated: {
        id: "inventoryItem.message.successfullyCreated",
        defaultMessage: "Inventory item successfully created",
        description: "Inventory item successfully created",
    },
    inventoryItemCreateError: {
        id: "inventoryItem.message.createError",
        defaultMessage: "Error while creating inventory item",
        description: "Error while creating inventory item",
    },
    newInventoryItem: {
        id: "inventoryItem.new",
        defaultMessage: "New inventory item",
        description: "New inventory item",
    },
    addNewInventoryItem: {
        id: "inventoryItem.actions.addNewInventoryItem",
        defaultMessage: "Add new inventory item",
        description: "Add new inventory item",
    },
    inventoryItemCreated: {
        id: "inventoryItem.form.inventoryItemCreated",
        defaultMessage: "Inventory item created",
        description: "Inventory item created",
    },
    inventoryImported: {
        id: "inventoryImported",
        defaultMessage: "Inventory successfully imported.",
        description: "Inventory successfully imported.",
    },
    unableToImport: {
        id: "unableToImport",
        defaultMessage: "Unable to import",
        description: "Unable to import",
    },
    inventoryUpload: {
        id: "inventoryUpload",
        defaultMessage: "Document successfully uploaded.",
        description: "Document successfully uploaded.",
    },
    inventoryUploadFail: {
        id: "inventoryUploadFail",
        defaultMessage: "Unable to upload",
        description: "Unable to upload",
    },
    continueEditingInventoryItemDetails: {
        id: "general.actions.continueEditingInventoryItemDetails",
        defaultMessage:
            "Inventory item successfully created. Do you want to continue editing inventory item details?",
        description:
            "Inventory item successfully created. Do you want to continue editing inventory item details?",
    },
    inventoryItemNotFound: {
        id: "inventoryItem.validation.notFound",
        defaultMessage: "Inventory item not found",
        description: "Inventory item not found",
    },
    inventoryItemConfirmDelete: {
        id: "inventoryItem.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this inventory item?",
        description: "Are you sure you want to delete this inventory item?",
    },
    department: {
        id: "department",
        defaultMessage: "Department",
    },
    departmentAddNew: {
        id: "departmentAddNew",
        defaultMessage: "Add new department",
        description: "Add new department",
    },
    locationAddNew: {
        id: "locationAddNew",
        defaultMessage: "Add new location",
        description: "Add new location",
    },
    documentClassificationRequirement: {
        id: "general.validation.requiredDocumentClassification",
        defaultMessage: "Document classification is required",
        description: "Document classification is required",
    },
    accessRightCreated: {
        id: "accessRight-created",
        defaultMessage: "Access right created",
    },
    continueEditingAccessRightDetails: {
        id: "general.actions.continueEditingAccessRightDetails",
        defaultMessage: "Do you want to continue editing access right details?",
    },
    continueEditingProjectDetails: {
        id: "general.actions.continueEditingProjectDetails",
        defaultMessage: "Do you want to continue editing project details?",
    },
    continueEditingServiceDetails: {
        id: "general.actions.continueEditingServiceDetails",
        defaultMessage: "Do you want to continue editing service details?",
    },
    isComputer: {
        id: "isComputer",
        defaultMessage: "Is computer?",
    },
    computerName: {
        id: "computerName",
        defaultMessage: "Computer name",
    },
    assignee: {
        id: "assignee",
        defaultMessage: "Assignee",
    },
    projectCreated: {
        id: "project.created",
        defaultMessage: "Project created",
    },
    serviceCreated: {
        id: "service.created",
        defaultMessage: "Service created",
    },
    serviceSuccessfullyCreated: {
        id: "service.successfullyCreated",
        defaultMessage: "Service successfully created",
        description: "Service successfully created",
    },
    unauthorizedHeader: {
        id: "unauthorized.header",
        defaultMessage: "Whoops!",
        description: "Whoops!",
    },
    unauthorizedMessage: {
        id: "unauthorized.message",
        defaultMessage:
            "It seems that you don’t have the permission to view or edit contents of this page. If you don’t think this is right, please contact your company admin.",
        description:
            "It seems that you don’t have the permission to view or edit contents of this page. If you don’t think this is right, please contact your company admin.",
    },
    unauthorizedRedirect: {
        id: "unauthorized.redirect",
        defaultMessage: "You will be automatically redirected to the homepage in",
        description: "You will be automatically redirected to the homepage in",
    },
    newsFeedProjectCreated: {
        id: "newsFeed.projectCreated",
        defaultMessage: "created a new project -",
        description: "created a new project -",
    },
    newsFeedRolesModified: {
        id: "newsFeed.rolesModified",
        defaultMessage: "modified project roles for",
        description: "modified project roles for",
    },
    newsFeedDetailsModified: {
        id: "newsFeed.detailsModified",
        defaultMessage: "modified details for the project",
        description: "modified details for the project",
    },
    added: {
        id: "newsFeed.added",
        defaultMessage: "added",
        description: "added",
    },
    addedA: {
        id: "newsFeed.addedA",
        defaultMessage: "added a",
        description: "added a",
    },
    report: {
        id: "newsFeed.report",
        defaultMessage: "report",
        description: "report",
    },
    Report: {
        id: "summaryReport",
        defaultMessage: "'s report",
        description: "'s report",
    },
    forTheProject: {
        id: "newsFeed.forTheProject",
        defaultMessage: "for the project",
        description: "for the project",
    },
    minutesOfMeeting: {
        id: "newsFeed.minutesOfTheMeeting",
        defaultMessage: "minutes of the meeting",
        description: "minutes of the meeting",
    },
    modified: {
        id: "newsFeed.modified",
        defaultMessage: "modified",
        description: "modified",
    },
    eventForTheProject: {
        id: "newsFeed.eventForTheProject",
        defaultMessage: "event for the project",
        description: "event for the project",
    },
    triggeredANewGitlab: {
        id: "newsFeed.triggeredANewGitlab",
        defaultMessage: "triggered a new Gitlab",
        description: "triggered a new Gitlab",
    },
    mentor: {
        id: "userDetails.mentor",
        defaultMessage: "Mentor",
        description: "Mentor",
    },
    attachments: {
        id: "attachments",
        defaultMessage: "Attachments",
    },
    addAttachment: {
        id: "addAttachment",
        defaultMessage: "Add attachment",
    },
    url: {
        id: "url",
        defaultMessage: "Url",
        description: "Sharepoint url",
    },
    PatentsGoodwill: {
        id: "Patents/Goodwill",
        defaultMessage: "Patents/Goodwill",
        description: "Patents/Goodwill",
    },
    ComputerPrograms: {
        id: "Computer programs",
        defaultMessage: "Computer programs",
        description: "Computer programs",
    },
    Unknown: {
        id: "Unknown",
        defaultMessage: "Unknown",
        description: "Unknown",
    },
    Computers: {
        id: "Computers",
        defaultMessage: "Computers",
        description: "Computers",
    },
    LandAndBuildings: {
        id: "Land and buildings",
        defaultMessage: "Land and buildings",
        description: "Land and buildings",
    },
    ProductionPlants: {
        id: "Production plants",
        defaultMessage: "Production plants",
        description: "Production plants",
    },
    Equipment: {
        id: "Equipment",
        defaultMessage: "Equipment",
        description: "Equipment",
    },
    SimCard: {
        id: "Sim card",
        defaultMessage: "Sim card",
        description: "Sim card",
    },
    FixedTermEmployment: {
        id: "FixedTermEmployment",
        defaultMessage: "Fixed term employment",
        description: "Fixed term employment",
    },
    IndefiniteTermEmployment: {
        id: "IndefiniteTermEmployment",
        defaultMessage: "Indefinite term employment",
        description: "Indefinite term employment",
    },
    ServiceContract: {
        id: "ServiceContract",
        defaultMessage: "Service contract",
        description: "Service contract",
    },
    CopyrightAgreement: {
        id: "CopyrightAgreement",
        defaultMessage: "Copyright agreement",
        description: "Copyright agreement",
    },
    StudentContract: {
        id: "StudentContract",
        defaultMessage: "Student contract",
        description: "Student contract",
    },
    Internship: {
        id: "Internship",
        defaultMessage: "Internship",
        description: "Internshipt",
    },
    Volunteering: {
        id: "Volunteering",
        defaultMessage: "Volunteering",
        description: "Volunteering",
    },
    ScriptContract: {
        id: "ScriptContract",
        defaultMessage: "Script contract",
        description: "Script contract",
    },
    Compact: {
        id: "Compact",
        defaultMessage: "Compact",
        description: "Compact",
    },
    Detailed: {
        id: "Detailed",
        defaultMessage: "Detailed",
        description: "Detailed",
    },
    FrameworkContract: {
        id: "FrameworkContract",
        defaultMessage: "Framework contract",
        description: "Framework contract",
    },
    ConfidentialityAgreement: {
        id: "ConfidentialityAgreement",
        defaultMessage: "Confidentiality agreement",
        description: "Confidentiality agreement",
    },
    AgreementOnProcurementOfServices: {
        id: "AgreementOnProcurementOfServices",
        defaultMessage: "Agreement on procurement of services",
        description: "Agreement on procurement of services",
    },
    ContractOnProcurementOfWorks: {
        id: "ContractOnProcurementOfWorks",
        defaultMessage: "Contract on procurement of works",
        description: "Contract on procurement of works",
    },
    AgreementOnThePurchaseOfProducts: {
        id: "AgreementOnThePurchaseOfProducts",
        defaultMessage: "Agreement on the purchase of products",
        description: "Agreement on the purchase of products",
    },
    AgreementsOnProcurementPurchaseAndSaleOfGoods: {
        id: "AgreementsOnProcurementPurchaseAndSaleOfGoods",
        defaultMessage: "Agreements on procurement purchase and sale of goods",
        description: "Agreements on procurement purchase and sale of goods",
    },
    LeaseAgreement: {
        id: "LeaseAgreement",
        defaultMessage: "Lease agreement",
        description: "Lease agreement",
    },
    RentalContract: {
        id: "RentalContract",
        defaultMessage: "Rental contract",
        description: "Rental contract",
    },
    PurchaseContract: {
        id: "PurchaseContract",
        defaultMessage: "Purchase contract",
        description: "Purchase contract",
    },
    GiftContract: {
        id: "GiftContract",
        defaultMessage: "Gift contract",
        description: "Gift contract",
    },
    LicenseAgreement: {
        id: "LicenseAgreement",
        defaultMessage: "License agreement",
        description: "License agreement",
    },
    MediationAgreement: {
        id: "MediationAgreement",
        defaultMessage: "Mediation agreement",
        description: "Mediation agreement",
    },
    AgreementOnTheTestingOfGoodsAndServices: {
        id: "AgreementOnTheTestingOfGoodsAndServices",
        defaultMessage: "Agreement on the testing of goods and services",
        description: "Agreement on the testing of goods and services",
    },
    InsuranceContract: {
        id: "InsuranceContract",
        defaultMessage: "Insurance contract",
        description: "Insurance contract",
    },
    LoanAgreement: {
        id: "LoanAgreement",
        defaultMessage: "Loan agreement",
        description: "Loan agreement",
    },
    contractAnnex: {
        id: "contractAnnex",
        defaultMessage: "Annex",
        description: "Annex",
    },
    contractAnnexAdd: {
        id: "contractAnnexAdd",
        defaultMessage: "Add annex",
        description: "Add annex",
    },
    contractAnnexCode: {
        id: "contractAnnexCode",
        defaultMessage: "Annex code",
        description: "Annex code",
    },
    requiredStartDate: {
        id: "requiredStartDate",
        defaultMessage: "Start date is required",
        description: "Start date is required",
    },
    requiredContractAnnexCode: {
        id: "requiredContractAnnexCode",
        defaultMessage: "Contract annex code is required",
        description: "Contract annex code is required",
    },
    inNegotiation: {
        id: "inNegotiation",
        defaultMessage: "In negotiation",
        description: "In negotiation",
    },
    signed: {
        id: "signed",
        defaultMessage: "Signed",
        description: "Signed",
    },
    beingSigned: {
        id: "beingSigned",
        defaultMessage: "Being signed",
        description: "Being signed",
    },
    annexDetails: {
        id: "annexDetails",
        defaultMessage: "Annex details",
        description: "Annex details",
    },
    editAnnex: {
        id: "editAnnex",
        defaultMessage: "Edit annex",
        description: "Edit annex",
    },
    contractAnnexDetailsValidateFetch: {
        id: "contractAnnexDetailsValidateFetch",
        defaultMessage: "Unable to fetch requested contract annex",
        description: "Unable to fetch requested contract annex",
    },
    contractAnnexDetailsValidateDelete: {
        id: "contractAnnexDetailsValidateDelete",
        defaultMessage: "Unable to delete selected contract annex",
        description: "Unable to delete selected contract annex",
    },
    contractAnnexDetailsValidateEdit: {
        id: "contractAnnexDetailsValidateEdit",
        defaultMessage: "Unable to edit selected contract annex",
        description: "Unable to edit selected contract annex",
    },
    offerAdd: {
        id: "offerAdd",
        defaultMessage: "Add offer",
        description: "Add offer",
    },
    offerDetails: {
        id: "offerDetails",
        defaultMessage: "Offer details",
        description: "Offer details",
    },
    editOffer: {
        id: "editOffer",
        defaultMessage: "Edit offer",
        description: "Edit offer",
    },
    offerDetailsValidateFetch: {
        id: "offerDetailsValidateFetch",
        defaultMessage: "Unable to fetch requested offer",
        description: "Unable to fetch requested offer",
    },
    offerDetailsValidateDelete: {
        id: "offerDetailsValidateDelete",
        defaultMessage: "Unable to delete selected offer",
        description: "Unable to delete selected offer",
    },
    offerDetailsValidateEdit: {
        id: "offerDetailsValidateEdit",
        defaultMessage: "Unable to edit selected offer",
        description: "Unable to edit selected offer",
    },
    deleteConfirmSingleFile: {
        id: "deleteConfirmSingleFIle",
        defaultMessage: "The file will be permanently deleted!",
    },
    contractAnnexAddNewFile: {
        id: "contractAnnexAddNewFile",
        defaultMessage: "Add new contract annex document",
        description: "Add new contract annex document",
    },
    projectDetailsValidationUser: {
        id: "projectDetails.requiredUser",
        defaultMessage: "User is required.",
        description: "User is required.",
    },
    projectDetailsValidationRole: {
        id: "projectDetails.requiredRole",
        defaultMessage: "Project role is required.",
        description: "Project role is required.",
    },
    reportSummaryValidation: {
        id: "reportSummary.requiredReport",
        defaultMessage: "No reports for selected date",
        description: "No reports for selected date",
    },
    partnerDetailsFirstNameValidation: {
        id: "partnerDetails.requiredFirstName",
        defaultMessage: "Contact's first name is required.",
        description: "Contact's first name is required.",
    },
    partnerDetailsLastNameValidation: {
        id: "partnerDetails.requiredLastName",
        defaultMessage: "Contact's last name is required.",
        description: "Contact's last name is required.",
    },
    partnerDetailsEmailValidation: {
        id: "partnerDetails.requiredEmail",
        defaultMessage: "Invalid email address. E.g. example@email.com",
        description: "Invalid email address. E.g. example@email.com",
    },
    partnerDetailsCompanyNameValidation: {
        id: "partnerDetails.requiredCompanyName",
        defaultMessage: "Partner's company name is required.",
        description: "Partner's company name is required.",
    },
    partnerDetailsTypeValidation: {
        id: "partnerDetails.requiredType",
        defaultMessage: "Partner type is required.",
        description: "Partner type is required.",
    },
    bankFormAddressValidation: {
        id: "bankForm.requiredAddress",
        defaultMessage: "Address is required",
        description: "Address is required",
    },
    bankFormCityValidation: {
        id: "bankForm.requiredCity",
        defaultMessage: "City is required",
        description: "City is required",
    },
    bankFormCountryValidation: {
        id: "bankForm.requiredCountry",
        defaultMessage: "Country is required",
        description: "Country is required",
    },
    ibanFormValidation: {
        id: "ibanForm.required",
        defaultMessage: "Account number is required",
        description: "Account number is required",
    },
    projectNotesFormAttendees: {
        id: "projectNotesForm.attendees",
        defaultMessage: "Attendees",
        description: "Attendees",
    },
    projectNotesFormPatnerAttendees: {
        id: "projectNotesForm.patnerAttendee",
        defaultMessage: "Partner attendees",
        description: "Partner attendees",
    },
    projectNotesFormNotes: {
        id: "projectNotesForm.notes",
        defaultMessage: "Notes",
        description: "Notes",
    },
    userDetailsRolesValidation: {
        id: "userDetails.validation.roleRequired",
        defaultMessage: "Minimum of 1 role is required",
        description: "Minimum of 1 role is required",
    },
    sickLeaveRequestDeletion: {
        id: "sickLeaveRequestDeletion",
        defaultMessage: "requested deletion of sick leave request",
        description: "requested deletion of sick leave request",
    },
    paidLeaveRequestDeletion: {
        id: "paidLeaveRequestDeletion",
        defaultMessage: "requested deletion of paid leave request",
        description: "requested deletion of paid leave request",
    },
    unpaidLeaveRequestDeletion: {
        id: "unpaidLeaveRequestDeletion",
        defaultMessage: "requested deletion of unpaid leave request",
        description: "requested deletion of unpaid leave request",
    },
    parentalLeaveRequestDeletion: {
        id: "parentalLeaveRequestDeletion",
        defaultMessage: "requested deletion of parental leave request",
        description: "requested deletion of parental leave request",
    },
    remoteWorkRequestDeletion: {
        id: "remoteWorkRequestDeletion",
        defaultMessage: "requested deletion of remote work request",
        description: "requested deletion of remote work request",
    },
    vacationRequestDeletion: {
        id: "vacationRequestDeletion",
        defaultMessage: "requested deletion of vacation request",
        description: "requested deletion of vacation request",
    },
    yearlyVacationPlanRequestDeletion: {
        id: "yearlyVacationPlanRequestDeletion",
        defaultMessage: "requested deletion of yearly vacation plan request",
        description: "requested deletion of yearly vacation plan request",
    },
    workingPlanApprovedEdited: {
        id: "workingPlanApprovedEdited",
        defaultMessage: "edited approved work plan",
        description: "edited approved work plan",
    },
    workingPlanRequestedEdited: {
        id: "workingPlanRequestedEdited",
        defaultMessage: "edited requested work plan",
        description: "edited requested work plan",
    },
    workingPlanRejectedEdited: {
        id: "workingPlanRejectedEdited",
        defaultMessage: "edited rejected work plan",
        description: "edited rejected work plan",
    },
    businessTrip: {
        id: "general.businessTrip",
        defaultMessage: "Business trip",
        description: "Business trip",
    },
    locoRide: {
        id: "locoRide",
        defaultMessage: "Loco ride",
        description: "Loco ride",
    },
    counterInitialState: {
        id: "locoRide.counterInitialState",
        defaultMessage: "Counter initial state",
        description: "Counter initial state",
    },
    counterEndState: {
        id: "locoRide.counterEndState",
        defaultMessage: "Counter end state",
        description: "Counter end state",
    },
    purpose: {
        id: "locoRide.purpose",
        defaultMessage: "Purpose",
        description: "Purpose",
    },
    roadRelation: {
        id: "locoRide.roadRelation",
        defaultMessage: "Road relation",
        description: "Road relation",
    },
    pricePerKm: {
        id: "locoRide.pricePerKm",
        defaultMessage: "Price Per Km",
        description: "Price Per Km",
    },
    otherCosts: {
        id: "locoRide.otherCosts",
        defaultMessage: "Other Costs",
        description: "Other Costs",
    },
    locoRides: {
        id: "locoRides",
        defaultMessage: "Loco rides",
        description: "Loco rides",
    },
    addLocoRide: {
        id: "locoRide.add",
        defaultMessage: "Add loco ride",
        description: "Add loco ride",
    },
    locoRideEdit: {
        id: "locoRide.edit",
        defaultMessage: "Edit loco ride",
        description: "Edit loco ride",
    },
    locoRideDetails: {
        id: "locoRide.details",
        defaultMessage: "Loco ride details",
        description: "Loco ride details",
    },
    counterEndStateGreaterThanInitial: {
        id: "locoRide.counterEndStateGreaterThanInitial",
        defaultMessage: "Counter end state have to be greater than initial state",
        description: "Counter end state have to be greater than initial state",
    },
    counterDifferenceGreaterThan30: {
        id: "locoRide.counterDifferenceGreaterThan30",
        defaultMessage: "Loco ride can be only up to 30km",
        description: "Loco ride can be only up to 30km",
    },
    seatLimitation: {
        id: "company.seatLimitation",
        defaultMessage: "Seat limitation",
        description: "Seat limitation",
    },
    seatLimitationReached: {
        id: "company.seatLimitationReached",
        defaultMessage: "You have reached the maximum seats limit. Please upgrade your plan to add new users.",
        description: "You have reached the maximum seats limit. Please upgrade your plan to add new users.",
    },
    seatLimitationFetchError: {
        id: "company.seatLimitationFetchError",
        defaultMessage: "Unable to fetch company seats left",
        description: "Znable to fetch company seats left",
    },
    purposeIsRequired: {
        id: "locoRide.purposeIsRequired",
        defaultMessage: "Purpose is required",
        description: "Purpose is required",
    },
    roadRelationIsRequired: {
        id: "locoRide.roadRelationIsRequired",
        defaultMessage: "Road relation is required",
        description: "Road relation is required",
    },
    pricePerKmIsRequired: {
        id: "locoRide.pricePerKmIsRequired",
        defaultMessage: "Price Per Km is required",
        description: "Price Per Km is required",
    },
    otherCostsIsRequired: {
        id: "locoRide.otherCostsIsRequired",
        defaultMessage: "Other Costs is required",
        description: "Other Costs is required",
    },
    vehicleModelIsRequired: {
        id: "locoRidevehicleModelIsRequired",
        defaultMessage: "Vehicle model is required",
        description: "Vehicle model is required",
    },
    vehicleManufacturerIsRequired: {
        id: "locoRide.vehicleManufacturerIsRequired",
        defaultMessage: "Vehicle manufacturer is required",
        description: "OVehicle manufacturer is required",
    },
    existingVehicleIsRequired: {
        id: "locoRide.existingVehicleIsRequired",
        defaultMessage: "You have to entry existing vehicle registration",
        description: "You have to entry existing vehicle registration",
    },
    vehicleRequired: {
        id: "locoRide.vehicleRequired",
        defaultMessage: "Vehicle is required",
        description: "Vehicle is required",
    },
    counterEndStateGreaterThanZero: {
        id: "locoRide.counterEndStateGreaterThanZero",
        defaultMessage: "Counter end state have to be greater than 0",
        description: "Counter end state have to be greater than 0",
    },
    locoRidesMessageDeleteSuccessfull: {
        id: "locoRide.locoRideMessageDeleteSuccessfull",
        defaultMessage: "Loco ride successfully deleted",
        description: "Loco ride successfully deleted",
    },
    locoRidesMessageDeleteError: {
        id: "locoRide.locoRideMessageDeleteError",
        defaultMessage: "Error while deleting loco ride",
        description: "Error while deleting loco ride",
    },
    locoRidesMessageCreateSuccessfull: {
        id: "locoRide.locoRideMessageCreateSuccessfull",
        defaultMessage: "Loco ride successfully created",
        description: "Loco ride successfully created",
    },
    locoRidesMessageCreateError: {
        id: "locoRide.locoRideMessageCreateError",
        defaultMessage: "Error while creating loco ride",
        description: "Error while creating loco ride",
    },
    locoRidesMessageEditError: {
        id: "locoRide.locoRideMessageEditError",
        defaultMessage: "Error while editing loco ride",
        description: "Error while editing loco ride",
    },
    locoRidesMessageFetchError: {
        id: "locoRide.locoRideMessageFetchError",
        defaultMessage: "Error while fetching loco ride",
        description: "Error while fetching loco ride",
    },
    vehiclesMessageFetchError: {
        id: "locoRide.vehiclesMessageFetchError",
        defaultMessage: "Error while fetching vehicles",
        description: "Error while fetching vehicles",
    },
    locoRideNotFound: {
        id: "locoRide.notFound",
        defaultMessage: "Loco ride not found",
        description: "Loco ride not found",
    },

    financeLastUpdatedInvoice: {
        id: "financeLastUpdatedInvoice",
        defaultMessage: "Last updated invoice:",
        description: "Last updated nvoice:",
    },
    board: {
        id: "general.board",
        defaultMessage: "Board",
        description: "Board",
    },
    education: {
        id: "education.form.education",
        defaultMessage: "Education",
        description: "Education",
    },
    institution: {
        id: "education.form.institution",
        defaultMessage: "Institution",
        description: "Institution",
    },
    certificationTitle: {
        id: "education.form.certificationTitle",
        defaultMessage: "Title",
        description: "Title",
    },
    addCertificate: {
        id: "education.form.addCertificate",
        defaultMessage: "Add certificate",
        description: "Add certificate",
    },
    duration: {
        id: "education.form.duration",
        defaultMessage: "Duration",
        description: "Duration",
    },
    uploadedAndParsedCertificate: {
        id: "uploadedAndParsedCertificate",
        defaultMessage: "Uploaded and parsed new certificate",
        description: "Uploaded and parsed new certificate",
    },
    certificateSuccessfullySubmitted: {
        id: "certificateSuccessfullySubmitted",
        defaultMessage: "Certificate successfully submited",
        description: "Certificate successfully submited",
    },
    saveCertificates: {
        id: "saveCertificates",
        defaultMessage: "Save certificates",
        description: "Save certificates",
    },
    confirmDeleteCertificate: {
        id: "certificates.validation.confirmDelete",
        defaultMessage: "Are you sure you want to delete this certificate?",
        description: "Are you sure you want to delete this certificate?",
    },
    institutionNameRequirement: {
        id: "certificatesForm.validation.requiredName",
        defaultMessage: "Institution is required",
        description: "Institution is required",
    },
    certificateSuccessfullyDeleted: {
        id: "certificate.validation.successfullyDeleted",
        defaultMessage: "Certificate successfully deleted",
        description: "Certificate successfully deleted",
    },
    certificateDetails: {
        id: "certificate.details",
        defaultMessage: "Certificate details",
        description: "Certificate details",
    },
    certificateNotFound: {
        id: "certificate.validation.notFound",
        defaultMessage: "Certificate not found",
        description: "Certificate not found",
    },
    unableToEditCertificateDetails: {
        id: "unableToEditCertificateDetails",
        defaultMessage: "Unable to edit certificate details",
        description: "Unable to edit certificate details",
    },
    editCertificate: {
        id: "editCertificate",
        defaultMessage: "Edit certificate",
        description: "Edit certificate",
    },
    certificate: {
        id: "certificate",
        defaultMessage: "Certificate",
        description: "Certificate",
    },
    tutorial: {
        id: "tutorial",
        defaultMessage: "Tutorial",
        description: "Tutorial",
    },
    unableToFetchCertificateDetails: {
        id: "unableToFetchCertificateDetails",
        defaultMessage: "Unable to fetch certificate details",
        description: "Unable to fetch certificate details",
    },
    recipient: {
        id: "recipient",
        defaultMessage: "Recipient",
    },
    sentYouADocument: {
        id: "sentYouADocument",
        defaultMessage: "sent you a document",
    },
    myWorkAttendances: {
        id: "myWorkAttendances",
        defaultMessage: "My work attendances",
    },
    certificates: {
        id: "certificates",
        defaultMessage: "Certificates",
        description: "Certificates",
    },
    tutorials: {
        id: "tutorials",
        defaultMessage: "Tutorials",
        description: "Tutorials",
    },
    addTutorial: {
        id: "tutorials.add",
        defaultMessage: "Add tutorial",
        description: "Add tutorial",
    },
    price: {
        id: "price",
        defaultMessage: "Price",
        description: "Price",
    },
    tutorialNameRequired: {
        id: "tutorialsForm.validation.requiredName",
        defaultMessage: "Name is required",
        description: "Name is required",
    },
    tutorialAuthorRequired: {
        id: "tutorialsForm.validation.requiredAuthor",
        defaultMessage: "Author is required",
        description: "Author is required",
    },
    tutorialUrlRequired: {
        id: "tutorialsForm.validation.requiredUrl",
        defaultMessage: "Url is required",
        description: "Url is required",
    },
    author: {
        id: "education.form.author",
        defaultMessage: "Author",
        description: "Author",
    },
    tutorialUrl: {
        id: "education.form.tutorialUrl",
        defaultMessage: "Url",
        description: "Url",
    },
    unableToFetchTutorialDetails: {
        id: "unableToFetchTutorialDetails",
        defaultMessage: "Unable to fetch tutorial details",
        description: "Unable to fetch tutorial details",
    },
    unableToEditTutorialDetails: {
        id: "unableToEditTutorialDetails",
        defaultMessage: "Unable to edit tutorial details",
        description: "Unable to edit tutorial details",
    },
    editTutorial: {
        id: "editTutorial",
        defaultMessage: "Edit tutorial",
        description: "Edit tutorial",
    },
    tutorialDetails: {
        id: "tutorial.details",
        defaultMessage: "Tutorial details",
        description: "Tutorial details",
    },
    remoteRequestNotification: {
        id: "remoteRequestNotification",
        defaultMessage:
            "Align with your manager at least two business day in advance before requesting remote work",
        description: "Remote notification",
    },
    documentsFetch: {
        id: "documents.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching documents",
        description: "Error while fetching documents",
    },
    documentDownload: {
        id: "documents.validation.unsuccessfulDownload",
        defaultMessage: "Error while downloading document",
        description: "Error while downloading document",
    },
    externalApproverEmail: {
        id: "externalApproverEmail",
        defaultMessage: "External approver email",
    },
    externalApproverApprovedVacation: {
        id: "externalApproverApprovedVacation",
        defaultMessage: "External approver has approved a vacation request",
    },
    externalApproverRejectedVacation: {
        id: "externalApproverRejectedVacation",
        defaultMessage: "External approver has rejected a vacation request",
    },
    pending: {
        id: "pending",
        defaultMessage: "Pending",
    },
    externalStatus: {
        id: "externalStatus",
        defaultMessage: "External",
    },
    externalApproverComment: {
        id: "externalApproverComment",
        defaultMessage: "External approver comment",
    },
    errorTryAgain: {
        id: "errorTryAgain",
        defaultMessage: "Error handling request, please try again later",
    },
    viewInventoryItemHistory: {
        id: "viewInventoryItemHistory",
        defaultMessage: "View inventory item history",
        description: "View inventory item history",
    },
    hideInventoryItemHistory: {
        id: "hideInventoryItemHistory",
        defaultMessage: "Hide inventory item history",
        description: "Hide inventory item history",
    },
    vacationRejectedByEmployer: {
        id: "vacationRejectedByEmployer",
        defaultMessage: "This vacation request has already been rejected by the employer",
    },
    vacationApprovedByEmployer: {
        id: "vacationApprovedByEmployer",
        defaultMessage: "This vacation request has already been approved by the employer",
    },
    vacationNoLongerExists: {
        id: "vacationNoLongerExists",
        defaultMessage: "This vacation request no longer exists",
    },
    noActionsNeeded: {
        id: "noActionsNeeded",
        defaultMessage: "No action is needed from your side",
    },
    existingAttachments: {
        id: "existingAttachments",
        defaultMessage: "Existing attachments",
    },
    fileDeletedWarning: {
        id: "fileDeletedWarning",
        defaultMessage: "The file be permanently deleted!",
    },
    errorAddingAttachmentToReport: {
        id: "errorAddingAttachmentToReport",
        defaultMessage: "error adding attachment to report",
    },
    projectStatus: {
        id: "projectStatus",
        defaultMessage: "Project status",
        description: "Project status",
    },
    projectType: {
        id: "projectType",
        defaultMessage: "Project type",
        description: "Project type",
    },
    of: {
        id: "general.of",
        defaultMessage: "of",
        description: "of",
    },
    items: {
        id: "general.items",
        defaultMessage: "items",
        description: "items",
    },
    selected: {
        id: "general.selected",
        defaultMessage: "selected",
        description: "selected",
    },
    totalValue: {
        id: "general.totalValue",
        defaultMessage: "total value",
        description: "total value",
    },
    newsletters: {
        id: "general.newsletters",
        defaultMessage: "Newsletters",
        description: "Newsletters",
    },
    pages: {
        id: "general.pages",
        defaultMessage: "pages",
        description: "pages",
    },
    probationPeriod: {
        id: "general.probationPeriod",
        defaultMessage: "Probation period expiration",
        description: "Probation period expiration",
    },
    dataFetchError: {
        id: "dataFetchingError",
        defaultMessage: "Error while fetching data",
        description: "Error while fetching data",
    },
    certificateDownloadError: {
        id: "certificateDownloadError",
        defaultMessage: "Unable to download certificate",
        description: "Unable to download certificate",
    },
    tutorialValidateAdd: {
        id: "tutorialValidateAdd",
        defaultMessage: "Unable to add new tutorial",
        description: "Unable to add new tutorial",
    },
    selectDocument: {
        id: "selectDocument",
        defaultMessage: "Select document",
        description: "Select document",
    },
    documentsValidateFetch: {
        id: "documentsValidateFetch",
        defaultMessage: "Unable to fetch documents",
        description: "Unable to fetch documents",
    },
    documentSuccessfullyAddedToinvoice: {
        id: "invoice.message.successfullyAddedToInvoice",
        defaultMessage: "Document successfully added to invoice",
        description: "Document successfully added to invoice",
    },
    documentAddToInvoiceFailed: {
        id: "invoice.message.addToInvoiceFailed",
        defaultMessage: "Error while trying to add document to invoice ",
        description: "Error while trying to add document to invoice",
    },
    descriptionOfChanges: {
        id: "descriptionOfChanges",
        defaultMessage: "Description of changes",
        description: "Description of changes",
    },
    userActivity: {
        id: "userActivity",
        defaultMessage: "User activity",
        description: "User activity",
    },
    userActivityValidateFetch: {
        id: "userActivity.validation.unsuccessfulFetchUserActivity",
        defaultMessage: "Unable to fetch user activity",
        description: "Unable to fetch user activity",
    },
    noUserActivity: {
        id: "userActivity.noUserActivity",
        defaultMessage: "No user activity found for selected user",
        description: "No user activity found for selected user",
    },
    loadMore: {
        id: "general.loadMore",
        defaultMessage: "Load more",
        description: "Load more",
    },
    payslipEmailText: {
        id: "payslipEmailText",
        defaultMessage: "Payslip email text",
    },
    addNewCompany: {
        id: "company.addNewCompany",
        defaultMessage: "Add new company",
        description: "Add new company",
    },
    companyAdminFirstName: {
        id: "company.adminFirstName",
        defaultMessage: "Company admin first name",
        description: "Company admin first name",
    },
    companyAdminLastName: {
        id: "company.adminLastName",
        defaultMessage: "Company admin last name",
        description: "Company admin last name",
    },
    companySuccessfullyCreated: {
        id: "company.validation.successfullyCreated",
        defaultMessage: "Company successfully created",
        description: "Company successfully created",
    },
    companyValidateCreate: {
        id: "company.validation.unsuccessfulCreate",
        defaultMessage: "Unable to add new company",
        description: "Unable to add new company",
    },

    hasABirthdays: {
        id: "hasABirthdays",
        defaultMessage: "has a birthday",
        description: "has a birthday",
    },
    birthdays: {
        id: "birthdays",
        defaultMessage: "Birthdays",
        description: "Birthdays",
    },
    today: {
        id: "today",
        defaultMessage: "Today",
        description: "Today",
    },
    projectSuccessfullyArchived: {
        id: "projects.validation.successfullyArchived",
        defaultMessage: "Project successfully archived",
        description: "Project successfully archived",
    },
    projectValidateArchive: {
        id: "projects.validation.unsuccessfulArchive",
        defaultMessage: "Unable to archive project",
        description: "Unable to archive project",
    },
    projectArchiveConfirmation: {
        id: "projects.archiveConfirmation",
        defaultMessage: "Are you sure you want to archive this project?",
        description: "Are you sure you want to archive this project?",
    },
    archive: {
        id: "general.archive",
        defaultMessage: "Archive",
        description: "Archive",
    },
    projectArchive: {
        id: "projects.archive",
        defaultMessage: "Project archive",
        description: "Project archive",
    },
    projectSuccessfullyUnarchived: {
        id: "projects.validation.successfullyUnarchived",
        defaultMessage: "Project successfully unarchived",
        description: "Project successfully unarchived",
    },
    projectValidateUnarchive: {
        id: "projects.validation.unsuccessfulUnarchive",
        defaultMessage: "Unable to unarchive project",
        description: "Unable to unarchive project",
    },
    projectUnarchiveConfirmation: {
        id: "projects.unarchiveConfirmation",
        defaultMessage: "Are you sure you want to unarchive this project?",
        description: "Are you sure you want to unarchive this project?",
    },
    projectsValidateFetch: {
        id: "projects.validation.unsuccessfulFetching",
        defaultMessage: "Error while fetching projects",
        description: "Error while fetching projects",
    },
    seconds: {
        id: "general.seconds",
        defaultMessage: "seconds",
        description: "seconds",
    },
    projectPropsValidateFetch: {
        id: "projectProps.validation.unsuccessfulFetch",
        defaultMessage: "Unable to fetch projects",
        description: "Unable to fetch projects",
    },
    projectEquipment: {
        id: "projectEquipment",
        defaultMessage: "Project equipment",
    },
    advanceAmount: {
        id: "advanceAmount",
        defaultMessage: "Advance amount",
    },
    dailyWage: {
        id: "dailyWage",
        defaultMessage: "Daily wage",
    },
    addDailyWage: {
        id: "dailyWage.add",
        defaultMessage: "Add daily wage",
    },
    finalReport: {
        id: "finalReport",
        defaultMessage: "Final report",
    },
    createNewTravelWarrant: {
        id: "travelWarrant.createNew",
        defaultMessage: "Create new travel warrant",
    },
    travelWarrantValidateFetch: {
        id: "travelWarrant.validateFetch",
        defaultMessage: "Unable to fetch travel warrants",
    },
    travelDestination: {
        id: "travelWarrant.travelDestination",
        defaultMessage: "Travel destination",
    },
    travelWarrantVehicle: {
        id: "travelWarrant.vehicle",
        defaultMessage: "Company vehicle",
    },
    travelWarrantPersonalVehicle: {
        id: "travelWarrant.personalVehicle",
        defaultMessage: "Personal vehicle",
    },
    boardNewsValidateFetch: {
        id: "board.validation.unsuccessfulFetching",
        defaultMessage: "Error while fetching board news",
        description: "Error while fetching board news",
    },
    boardNewsValidateDelete: {
        id: "board.validation.unsuccessfulDelete",
        defaultMessage: "Error while deleting board news",
        description: "Error while deleting board news",
    },
    boardNewsValidateCreate: {
        id: "board.validation.unsuccessfulCreate",
        defaultMessage: "Error while creating board news",
        description: "Error while creating board news",
    },
    boardNewsValidateEdit: {
        id: "board.validation.unsuccessfulEdit",
        defaultMessage: "Error while editing board news",
        description: "Error while editing board news",
    },
    boardNewsCreate: {
        id: "board.validation.successfulCreate",
        defaultMessage: "Board news successfully created",
        description: "Board news successfully created",
    },
    boardNewsEdit: {
        id: "board.validation.successfulEdit",
        defaultMessage: "Board news successfully edited",
        description: "Board news successfully edited",
    },
    adminNotes: {
        id: "userDetails.adminNotes",
        defaultMessage: "Admin notes",
        description: "Admin notes",
    },
    expenses: {
        id: "general.expenses",
        defaultMessage: "Expenses",
        description: "Expenses",
    },
    outgoing: {
        id: "general.outgoing",
        defaultMessage: "Outgoing",
        description: "Outgoing",
    },
    incoming: {
        id: "general.incoming",
        defaultMessage: "Incoming",
        description: "Incoming",
    },
    hqDeleteWarning: {
        id: "hqDeleteWarning",
        defaultMessage: "You cannot delete an address that is currently marked as main",
        description: "You cannot delete an address that is currently marked as main",
    },
    january: {
        id: "general.months.january",
        defaultMessage: "January",
        description: "January",
    },
    february: {
        id: "general.months.february",
        defaultMessage: "February",
        description: "February",
    },
    march: {
        id: "general.months.march",
        defaultMessage: "March",
        description: "March",
    },
    april: {
        id: "general.months.april",
        defaultMessage: "April",
        description: "April",
    },
    may: {
        id: "general.months.may",
        defaultMessage: "May",
        description: "May",
    },
    june: {
        id: "general.months.june",
        defaultMessage: "June",
        description: "June",
    },
    july: {
        id: "general.months.july",
        defaultMessage: "July",
        description: "July",
    },
    august: {
        id: "general.months.august",
        defaultMessage: "August",
        description: "August",
    },
    september: {
        id: "general.months.september",
        defaultMessage: "September",
        description: "September",
    },
    october: {
        id: "general.months.october",
        defaultMessage: "October",
        description: "October",
    },
    november: {
        id: "general.months.november",
        defaultMessage: "November",
        description: "November",
    },
    december: {
        id: "general.months.december",
        defaultMessage: "December",
        description: "December",
    },
    none: {
        id: "general.none",
        defaultMessage: "None",
        description: "None",
    },
    monthTotal: {
        id: "expenses.monthTotal",
        defaultMessage: "Month Total",
        description: "Month Total",
    },
    categoryTotal: {
        id: "expenses.categoryTotal",
        defaultMessage: "Category Total",
        description: "Category Total",
    },
    addNewsletter: {
        id: "newsletters.add",
        defaultMessage: "Add newsletter",
        description: "Add newsletter",
    },
    expirationDate: {
        id: "newsletters.expirationDate",
        defaultMessage: "Expiration date",
        description: "Expiration date",
    },
    expirationDateRequired: {
        id: "newsletters.expirationDateRequired",
        defaultMessage: "Expiration date required",
        description: "Expiration date required",
    },
    newsletterNameRequired: {
        id: "newsletters.nameRequired",
        defaultMessage: "Newsletter name is required",
        description: "Newsletter name is required",
    },
    ownerRequired: {
        id: "newsletters.ownerRequired",
        defaultMessage: "Newsletter owner is required",
        description: "Newsletter owner is required",
    },
    newslettersValidateFetch: {
        id: "newsletters.validation.unsuccessfulFetching",
        defaultMessage: "Error while fetching board news",
        description: "Error while fetching board news",
    },
    newslettersValidateDelete: {
        id: "newsletters.validation.unsuccessfulDelete",
        defaultMessage: "Error while deleting newsletter",
        description: "Error while deleting newsletter",
    },
    newslettersValidateCreate: {
        id: "newsletters.validation.unsuccessfulCreate",
        defaultMessage: "Error while creating newsletter",
        description: "Error while creating newsletter",
    },
    newslettersValidateEdit: {
        id: "newsletters.validation.unsuccessfulEdit",
        defaultMessage: "Error while editing newsletter",
        description: "Error while editing newsletter",
    },
    newslettersCreate: {
        id: "newsletters.validation.successfulCreate",
        defaultMessage: "Newsletter successfully created",
        description: "Newsletter successfully created",
    },
    newslettersEdit: {
        id: "newsletters.validation.successfulEdit",
        defaultMessage: "Newsletter successfully edited",
        description: "Newsletter successfully edited",
    },
    editNewsletter: {
        id: "newsletters.edit",
        defaultMessage: "Edit newsletter",
        description: "Edit newsletter",
    },
    ibansValidateDelete: {
        id: "ibans.validation.unsuccessfulDelete",
        defaultMessage: "Error while deleting iban",
        description: "Error while deleting iban",
    },
    ibanDetailsValidateFetch: {
        id: "iban.validation.unsuccessfulFetch",
        defaultMessage: "Error while fetching iban details",
        description: "Error while fetching iban details",
    },
    ibansValidateCreate: {
        id: "ibans.validation.unsuccessfulCreate",
        defaultMessage: "Error while creating iban",
        description: "Error while creating iban",
    },
    ibansValidateEdit: {
        id: "ibans.validation.unsuccessfulEdit",
        defaultMessage: "Error while editing iban",
        description: "Error while editing iban",
    },
    ibansCreate: {
        id: "ibans.validation.successfulCreate",
        defaultMessage: "Iban successfully created",
        description: "Iban successfully created",
    },
    ibansEdit: {
        id: "ibans.validation.successfulEdit",
        defaultMessage: "Iban successfully edited",
        description: "Iban successfully edited",
    },
    editIban: {
        id: "ibans.edit",
        defaultMessage: "Edit iban",
        description: "Edit iban",
    },
    ibanDetails: {
        id: "ibans.details",
        defaultMessage: "Iban details",
        description: "Iban details",
    },
    management: {
        id: "Management",
        defaultMessage: "Management",
        description: "Management",
    },
    legalServices: {
        id: "LegalServices",
        defaultMessage: "Legal services",
        description: "Legal services",
    },
    offers: {
        id: "general.offers",
        defaultMessage: "Offers",
        description: "Offers",
    },
    offer: {
        id: "general.offer",
        defaultMessage: "Offer",
        description: "Offer",
    },
    offerAddNewFile: {
        id: "offerAddNewFile",
        defaultMessage: "Add new offer document",
        description: "Add new offer document",
    },
    offerSuccessfullyAccepted: {
        id: "offers.validation.successfullyAccepted",
        defaultMessage: "Offer successfully accepted",
        description: "Offer successfully accepted",
    },
    offerValidateAcceptance: {
        id: "offers.validation.unsuccessfulAccepted",
        defaultMessage: "Unable to accept offer",
        description: "Unable to accept offer",
    },
   offerAcceptanceConfirmation: {
        id: "offers.acceptanceConfirmation",
        defaultMessage: "Are you sure you want to change acceptance this offer?",
       description: "Are you sure you want to change acceptance this offer?",
    },
    offerUnacceptanceConfirmation: {
        id: "offers.unacceptanceConfirmation",
        defaultMessage: "Are you sure you want to unaccept this offer?",
        description: "Are you sure you want to unaccept this offer?",
    },
    accepted: {
        id: "general.accepted",
        defaultMessage: "Accepted",
        description: "Accepted",
    },
    notAccepted: {
        id: "general.notAccepted",
        defaultMessage: "Not accepted",
        description: "Not accepted",
    },
    accept: {
        id: "general.accept",
        defaultMessage: "Accept",
        description: "Accept",
    },
    unaccept: {
        id: "general.unaccept",
        defaultMessage: "Unaccept",
        description: "Unaccept",
    },
    startTyping: {
        id: "general.startTyping",
        defaultMessage: "Start typing ...",
        description: "Start typing ...",
    },
    assigned: {
        id: "inventoryItem.assigned",
        defaultMessage: "Assigned",
        description: "Assigned",
    },
    unassigned: {
        id: "inventoryItem.unassigned",
        defaultMessage: "Unassigned",
        description: "Unassigned",
    },
    lastAssignedDate: {
        id: "inventoryItem.lastAssignedDate",
        defaultMessage: "Last assigned date",
        description: "Last assigned date",
    },
    lastAssignedUser: {
        id: "inventoryItem.lastAssignedUser",
        defaultMessage: "Last assigned user",
        description: "Last assigned user",
    },
    newsletterExpirationReminder: {
        id: "newsletterExpirationReminder",
        defaultMessage: "Some #newsletters #expire in 1 week",
        description: "Some #newsletters #expire in 1 week",
    },
    latestDailyReportMissing: {
        id: "latestDailyReportMissing",
        defaultMessage: "Latest daily report missing",
        description: "Latest daily report missing",
    },
});
