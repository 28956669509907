import { initialState as user } from "../store/userReducer";
import { initialState as toastMessage } from "../store/toastMessageReducer";
import { initialState as projectRoles } from "../store/projectRolesReducer";
import { initialState as projects } from "../store/projectsReducer";
import { initialState as staff } from "../store/staffReducer";
import { initialState as company } from "../store/companyReducer";
import { initialState as languages } from "../store/languagesReducer";
import { initialState as loginRedirectPath } from "../store/loginRedirectPathReducer";
import { initialState as appStage } from "../store/appStageReducer";
import { initialState as notifications } from "../store/notificationsReducer";
import { initialState as appSize } from "../store/appSizeReducer";
import { AppState } from "../store/configureStore";

export type ClearAppState = Omit<AppState, "login">;

export const getClearState: () => ClearAppState = () => {
    return {
        user,
        toastMessage,
        projects,
        projectRoles,
        staff,
        company,
        languages,
        appStage,
        notifications,
        loginRedirectPath,
        appSize
    };
};
