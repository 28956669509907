import { applyMiddleware, combineReducers, compose, createStore, Reducer } from "redux";
import { LoginState, loginReducer as login } from "./loginReducer";
import { UserData } from "../models/userData";
import { userReducer as user } from "./userReducer";
import { toastMessageReducer as toastMessage, ToastMessageState } from "./toastMessageReducer";
import { projectRolesReducer as projectRoles, ProjectRolesState } from "./projectRolesReducer";
import { projectsReducer as projects, ProjectsState } from "./projectsReducer";
import { staffReducer as staff, StaffState } from "./staffReducer";
import { companyReducer as company } from "./companyReducer";
import { languagesReducer as languages } from "./languagesReducer";
import { loginRedirectPathReducer as loginRedirectPath } from "./loginRedirectPathReducer";
import { ILanguage } from "../models/language";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root";
import { ICompany } from "../models/company";
import { appStageReducer as appStage, AppStageState } from "./appStageReducer";
import { notificationsReducer as notifications } from "./notificationsReducer";
import { appSizeReducer as appSize } from "./appSizeReducer";
import { INotification } from "../models/notification";
import { CLEAR_REDUX } from "../actions/authentificationActions";
import { getClearState } from "../helpers/ReduxHelper";
import { AppSize } from "../models/appSize";

export interface AppState {
    login: LoginState;
    user: UserData;
    toastMessage: ToastMessageState;
    projects: ProjectsState[];
    projectRoles: ProjectRolesState[];
    staff: StaffState[];
    company: ICompany;
    languages: ILanguage[];
    appStage: AppStageState;
    notifications: INotification[];
    loginRedirectPath: string | null;
    appSize: AppSize
}

const configureStore = (initialState?: AppState) => {
    const clearState = getClearState();

    const appReducer = combineReducers<AppState>({
        login,
        user,
        toastMessage,
        projects,
        projectRoles,
        staff,
        company,
        languages,
        appStage,
        notifications,
        loginRedirectPath,
        appSize
    });

    const rootReducer: Reducer<AppState> = (state, action) => {
        if (action.type === CLEAR_REDUX && state) {
            return appReducer({ login: state.login, ...clearState }, action);
        }
        return appReducer(state, action);
    };

    const sagaMiddleware = createSagaMiddleware();

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const result = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(sagaMiddleware), ...enhancers)
    );

    sagaMiddleware.run(rootSaga);

    return result;
};

export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
