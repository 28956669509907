import Messages from "../localization/Messages";
import { IntlShape } from "react-intl";

export interface IPaginationWrapper<T> {
    total: number;
    items: T[];
}

export enum ApproveStatus {
    All = -1,
    Draft,
    Rejected,
    Approved,
    UserCancelled,
    Requested,
    InReview,
    Postponed,
    Completed,
    DeleteRequested,
    Unknown = 999,
}

export enum Role {
    Admin = 0,
    Employee = 1,
    Student = 2,
    External = 3,
    Lawyer = 4,
}

export const commonStatusOptions = [
    { key: 4, label: "Requested" },
    { key: 2, label: "Approved" },
    { key: 1, label: "Rejected" },
];

export const getCommonStatusOptions = (intl: IntlShape, isAdmin?: boolean) => {
    let options = [
        { key: 4, label: intl.formatMessage(Messages.requested) },
        { key: 2, label: intl.formatMessage(Messages.approved) },
        { key: 1, label: intl.formatMessage(Messages.rejected) },
    ];
    return options;
};

export const getStatusLabel = (intl: IntlShape, status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Draft:
            return intl.formatMessage(Messages.draft);
        case ApproveStatus.UserCancelled:
            return intl.formatMessage(Messages.userCancelled);
        case ApproveStatus.InReview:
            return intl.formatMessage(Messages.inReview);
        case ApproveStatus.Approved:
            return intl.formatMessage(Messages.approved);
        case ApproveStatus.Requested:
            return intl.formatMessage(Messages.requested);
        case ApproveStatus.Rejected:
            return intl.formatMessage(Messages.rejected);
        case ApproveStatus.Completed:
            return intl.formatMessage(Messages.completed);
        default:
            return status;
    }
};

//sync with Aconto_v3
export const getStatusColor = (status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Approved:
            return "green";
        case ApproveStatus.Requested:
            return "yellow";
        case ApproveStatus.Rejected:
            return "red";
        case ApproveStatus.Draft:
            return "purple";
        case ApproveStatus.UserCancelled:
            return "grey";
        case ApproveStatus.Completed:
            return "olive";
        default:
            return "grey";
    }
};

export const approveStatusDisplayText = (intl: IntlShape, status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Draft:
            return intl.formatMessage(Messages.draft);
        case ApproveStatus.Rejected:
            return intl.formatMessage(Messages.rejected);
        case ApproveStatus.Approved:
            return intl.formatMessage(Messages.approved);
        case ApproveStatus.UserCancelled:
            return intl.formatMessage(Messages.userCancelled);
        case ApproveStatus.Requested:
            return intl.formatMessage(Messages.requested);
        case ApproveStatus.InReview:
            return intl.formatMessage(Messages.inReview);
        case ApproveStatus.Postponed:
            return intl.formatMessage(Messages.postponed);
        case ApproveStatus.Completed:
            return intl.formatMessage(Messages.completed);
        case ApproveStatus.DeleteRequested:
            return intl.formatMessage(Messages.deleteRequested);
        default:
            return intl.formatMessage(Messages.unknown);
    }
};

export const getApproveStatusEnum = (text: string): ApproveStatus => {
    switch (text) {
        case "draft":
            return ApproveStatus.Draft;
        case "rejected":
            return ApproveStatus.Rejected;
        case "approved":
            return ApproveStatus.Approved;
        case "user-cancelled":
            return ApproveStatus.UserCancelled;
        case "requested":
            return ApproveStatus.Requested;
        case "in-review":
            return ApproveStatus.InReview;
        case "postponed":
            return ApproveStatus.Postponed;
        case "completed":
            return ApproveStatus.Completed;
        default:
            return ApproveStatus.Unknown;
    }
};

export const getApproveStatusLink = (status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Draft:
            return "draft";
        case ApproveStatus.Rejected:
            return "rejected";
        case ApproveStatus.Approved:
            return "approved";
        case ApproveStatus.UserCancelled:
            return "user-cancelled";
        case ApproveStatus.Requested:
            return "requested";
        case ApproveStatus.InReview:
            return "in-review";
        case ApproveStatus.Postponed:
            return "postponed";
        case ApproveStatus.Completed:
            return "completed";
        default:
            return "unknown";
    }
};

export const getApproveStatusActiveIndex = (approveStatus: string) => {
    switch (approveStatus) {
        case "requested":
            return 0;
        case "approved":
            return 1;
        case "rejected":
            return 2;
        default:
            return 0;
    }
};
