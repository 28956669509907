import { SelectItem } from "primereact/selectitem";
import { StaffState } from "../store/staffReducer";
import { Role } from "./common";
import { EmploymentType } from "./userDetails";

export interface UserData {
    guid?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    roles?: UserRole[];
    jobTitleGuid?: string;
    userJobTitle?: string;
    token?: string;
    passwordRecoveryToken?: string;
    locale?: Locale;
    isEmployed?: boolean;
    points?: number;
    negativePoints?: number;
    employeesViewEnabled?: boolean;
    remoteEnabled?: boolean;
}

export enum UserRole {
    Admin = "Admin",
    Employee = "Employee",
    Student = "Student",
    External = "External",
    Lawyer = "Lawyer",
    SuperAdmin = "SuperAdmin",
}

const toRoleNumber = (role: UserRole) => {
    switch (role) {
        case UserRole.Admin:
            return Role.Admin;
        case UserRole.Employee:
            return Role.Employee;
        case UserRole.Student:
            return Role.Student;
        case UserRole.External:
            return Role.External;
        case UserRole.Lawyer:
            return Role.Lawyer;
        default:
            return undefined;
    }
};

export const userDataToStaffState = (user: UserData): StaffState => {
    return {
        label: `${user.firstName} ${user.lastName}`,
        value: user.guid!,
        roles: user.roles ? user.roles.map(r => toRoleNumber(r)!) : [],
        isEmployed: user.isEmployed,
    };
};

export const userRoles: string[] = Object.values(UserRole).filter(
    value => typeof value === "string"
);

export const userRolesAsSelectItems = (): SelectItem[] => {
    return userRoles.map(role => ({ label: role, value: role } as SelectItem));
};

export interface INewUser extends UserData {
    password: string;
    employmentType: number;
    endDate?: string;
    departmentGuid?: string;
}

export const userInit: INewUser = {
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    roles: [],
    jobTitleGuid: "",
    employmentType: EmploymentType.Temporary,
};

export enum Locale {
    en = 0,
    hr = 1,
}
