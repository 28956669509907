import { StaffState } from "../store/staffReducer";
import { IWorkDate } from "./workDate";
import Messages from "../localization/Messages";
import { IntlShape } from "react-intl";
import { IDocument } from "./document";

export interface IWorkAttendance {
    guid: string;
    status: number;
    user?: string;
    userGuid?: string;
    created?: string;
    attendanceType: WorkAttendanceType;
    reason?: string; // Required for remote work.
    dates: IWorkDate[];
    vacationDaysLeft?: number;
    isAuditable?: boolean;
    vacationDiffersFromPlan?: boolean;
    documents: IDocument[];
    isOngoing: boolean;
    externalApproveStatus: ExternalApproveStatus;
    externalApproveComment?: string;
    startDate: string;
    endDate: string;
}

export enum WorkAttendanceType {
    Vacation = 1,
    SickLeave = 2,
    Office = 4,
    PaidLeave = 5,
    ParentalLeave = 6,
    YearlyVacationPlan = 7,
    Present = 8,
    Remote = 9,
    Weekend = 10,
    Absent = 11,
    UnpaidLeave = 12,
}

export enum ExternalApproveStatus {
    NotNeeded = 0,
    Pending,
    Approved,
    Rejected,
}

export interface IUpcomingBirthday {
    imgSrc?: string;
    fullName: string;
    date: string;
}

export interface IUserDto {
    staff: StaffState[];
    page: number;
}

export const IUserDtoInit: IUserDto = {
    staff: [],
    page: 0,
};

export interface ITableData {
    tableData: { [key: string]: string | number };
}

export interface IHomeDataWrapper {
    upcomingBirthdays: IUpcomingBirthday[];
    latestBankStatementDate: string;
}

export const workAttendanceDisplayText = (intl: IntlShape, type: WorkAttendanceType) => {
    switch (type) {
        case WorkAttendanceType.Vacation:
            return intl.formatMessage(Messages.vacation);
        case WorkAttendanceType.SickLeave:
            return intl.formatMessage(Messages.sickLeave);
        case WorkAttendanceType.Office:
            return intl.formatMessage(Messages.office);
        case WorkAttendanceType.PaidLeave:
            return intl.formatMessage(Messages.paidLeave);
        case WorkAttendanceType.ParentalLeave:
            return intl.formatMessage(Messages.parentalLeave);
        case WorkAttendanceType.YearlyVacationPlan:
            return intl.formatMessage(Messages.yearlyVacationPlan);
        case WorkAttendanceType.Present:
            return intl.formatMessage(Messages.present);
        case WorkAttendanceType.Remote:
            return intl.formatMessage(Messages.remote);
        case WorkAttendanceType.Weekend:
            return intl.formatMessage(Messages.weekend);
        case WorkAttendanceType.Absent:
            return intl.formatMessage(Messages.absent);
        case WorkAttendanceType.UnpaidLeave:
            return intl.formatMessage(Messages.unpaidLeave);
        default:
            return intl.formatMessage(Messages.unknown);
    }
};

export const getWorkAttendanceEnum = (text: string): WorkAttendanceType => {
    switch (text) {
        case "vacation":
            return WorkAttendanceType.Vacation;
        case "sick-leave":
            return WorkAttendanceType.SickLeave;
        case "office":
            return WorkAttendanceType.Office;
        case "paid-leave":
            return WorkAttendanceType.PaidLeave;
        case "parental-leave":
            return WorkAttendanceType.ParentalLeave;
        case "yearly-vacation-plan":
            return WorkAttendanceType.YearlyVacationPlan;
        case "present":
            return WorkAttendanceType.Present;
        case "remote":
            return WorkAttendanceType.Remote;
        case "weekend":
            return WorkAttendanceType.Weekend;
        case "absent":
            return WorkAttendanceType.Absent;
        case "unpaid-leave":
            return WorkAttendanceType.UnpaidLeave;
        default:
            return -1;
    }
};

export const getWorkAttendanceLink = (type: WorkAttendanceType) => {
    switch (type) {
        case WorkAttendanceType.Vacation:
            return "vacation";
        case WorkAttendanceType.SickLeave:
            return "sick-leave";
        case WorkAttendanceType.Office:
            return "office";
        case WorkAttendanceType.PaidLeave:
            return "paid-leave";
        case WorkAttendanceType.ParentalLeave:
            return "parental-leave";
        case WorkAttendanceType.YearlyVacationPlan:
            return "yearly-vacation-plan";
        case WorkAttendanceType.Present:
            return "present";
        case WorkAttendanceType.Remote:
            return "remote";
        case WorkAttendanceType.Weekend:
            return "weekend";
        case WorkAttendanceType.Absent:
            return "absent";
        case WorkAttendanceType.UnpaidLeave:
            return "unpaid-leave";
        default:
            return "";
    }
};

export const workAttendanceInit: IWorkAttendance = {
    guid: "",
    status: -1,
    attendanceType: -1,
    reason: "",
    dates: [],
    documents: [],
    isOngoing: false,
    externalApproveStatus: ExternalApproveStatus.NotNeeded,
    startDate: "",
    endDate: "",
};

export interface IMonthlyWorkAttendance {
    userId: string;
    vacations: number;
    sickLeaves: number;
    paidLeaves: number;
    parentalLeaves: number;
    remotes: number;
}

export interface IExternalVacationDetails {
    workAttendance: IWorkAttendance;
    jobTitle?: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface IExternalVacationAction {
    isApproved: boolean;
    token: string;
    comment?: string;
}
