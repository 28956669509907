import { Reducer } from "redux";
import { SET_STAFF, ADD_STAFF } from "../actions/staffActions";
import { Role } from "../models/common";

export interface StaffState {
    // SelectItem!
    label: string;
    value: string;
    roles: Role[];
    isEmployed?: boolean;
}

export const initialState: StaffState[] = [];

export const staffReducer: Reducer<StaffState[]> = (
    state: StaffState[] = initialState,
    action: any
): StaffState[] => {
    switch (action.type) {
        case SET_STAFF:
            return action.payload;
        case ADD_STAFF:
            return [...state, action.payload];
        default:
            return state;
    }
};

export const getRoleName = (role: string | undefined) => {
    if (role) {
        const clean = role.replace(/_/g, " ");
        return clean.charAt(0).toUpperCase() + clean.slice(1);
    } else return "";
};
